import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import moment from "moment";
import { getLatestCompany, timeGapYearMonth } from "../../util/fn";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import CvTemplate from "../Cv/CvTemplete";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

const DownloadCv = (props) => {
  const { id } = props.match.params;

  const history = useHistory();

  const [profileData, setProfileData] = useState();
  const { response, request } = useRequest();

  useEffect(() => {
    if (id) {
      request("GET", `job-seeker-cv/get-complete/${id}`);
    }
  }, [id]);

  useEffect(() => {
    if (response && response.status) {
      setProfileData(response?.data);
    }
  }, [response]);

  const levelPercentage = {
    basic: "25%",
    average: "50%",
    good: "75%",
    expert: "100%",
  };

  const downloadCv = async () => {
    const blob = await pdf(<CvTemplate profileData={profileData} />).toBlob();
    saveAs(blob, "Reports.pdf");
  };

  let latestCompany = getLatestCompany(profileData?.workExperience);

  return (
    <>
      <button
        className="btn btn-primary"
        style={{
          width: "150px",
          marginLeft: "40px",
          padding: "10px",
          marginTop: "-29px",
          fontSize: "18px",
        }}
        onClick={downloadCv}
      >
        Download Cv
      </button>
      <div
        id="divToPrint"
        style={{
          background: "#fff",
          width: "950px",
          margin: "20px auto",
          padding: "30px 30px",
        }}
      >
        <table
          align="center"
          cellPadding="0"
          cellSpacing="0"
          height="100%"
          width="100%"
          style={{ backgroundColor: "#ffffff" }}
        >
          <tbody>
            <tr>
              <td style={{ display: "flex", alignItems: "center" }}>
                {/* <img
              src={`${API}/${profilePic}`}
              alt="abc"
              // src="/img/user.png"
              style={{ width: "150px", height: "150px", borderRadius: "8px" }}
            /> */}
                <div className="tablerow" style={{ marginLeft: "10px" }}>
                  <h3>
                    {profileData?.general?.first_name}{" "}
                    {profileData?.general?.last_name}
                  </h3>
                  <p>{latestCompany?.job_title}</p>
                </div>
              </td>
              <td></td>
            </tr>
            <tr style={{ verticalAlign: "top" }}>
              <td
                align="left"
                style={{
                  textAlign: "left",
                  width: "700px",
                  paddingRight: "20px",
                }}
              >
                <table
                  width="100%"
                  cellSpacing="0"
                  cellPadding="0"
                  style={{ marginTop: "30px", marginBottom: "10px" }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <h5
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "2px",
                            marginBottom: "8px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20px"
                            viewBox="0 0 448 512"
                            style={{ marginRight: "10px" }}
                          >
                            <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z" />
                          </svg>
                          <span
                            style={{
                              fontSize: "28px",
                              color: "#000",
                              fontWeight: "500",
                            }}
                          >
                            Profile
                          </span>
                        </h5>
                        <div style={{ paddingLeft: "30px" }}>
                          <p>{profileData?.general?.about}</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  width="100%"
                  cellSpacing="0"
                  cellPadding="0"
                  style={{ marginTop: "30px", marginBottom: "10px" }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <h5
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "2px",
                            marginBottom: "8px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20px"
                            viewBox="0 0 512 512"
                            style={{ marginRight: "10px" }}
                          >
                            <path d="M128 480h256V80c0-26.5-21.5-48-48-48H176c-26.5 0-48 21.5-48 48v400zm64-384h128v32H192V96zm320 80v256c0 26.5-21.5 48-48 48h-48V128h48c26.5 0 48 21.5 48 48zM96 480H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48h48v352z" />
                          </svg>
                          <span
                            style={{
                              fontSize: "28px",
                              color: "#000",
                              fontWeight: "500",
                            }}
                          >
                            Employment History
                          </span>
                        </h5>

                        {profileData?.workExperience?.map((val) => {
                          return (
                            <div key={val.id} style={{ paddingLeft: "30px" }}>
                              <span
                                style={{
                                  fontSize: "20px",
                                  color: "#000",
                                  fontWeight: "500",
                                  lineHeight: "1",
                                  marginBottom: "10px",
                                }}
                              >
                                {val?.job_title}
                              </span>
                              <ul
                                style={{ display: "flex", padding: "0px 10px" }}
                              >
                                <li style={{ listStyle: "none" }}>
                                  {" "}
                                  {val.company_name}{" "}
                                </li>
                                <li
                                  style={{
                                    listStyle: "disc",
                                    marginLeft: "35px",
                                  }}
                                >
                                  {val.job_type_name}
                                </li>
                                <li
                                  style={{
                                    listStyle: "disc",
                                    marginLeft: "35px",
                                  }}
                                >
                                  {moment(val.start_date).format("MMM-YY")} -{" "}
                                  {val?.end_date
                                    ? moment(val.end_date).format("MMM-YY")
                                    : "Present"}{" "}
                                  (
                                  {timeGapYearMonth(
                                    val.start_date,
                                    val?.end_date || moment()
                                  )}
                                  )
                                </li>
                              </ul>
                              <ul style={{ paddingLeft: "30px" }}>
                                <li
                                  style={{
                                    listStyle: "disc",
                                  }}
                                >
                                  Achievements : {val.achievements}{" "}
                                </li>
                                <li
                                  style={{
                                    listStyle: "disc",
                                  }}
                                >
                                  Responsibilities : {val.responsibilities}
                                </li>
                              </ul>
                            </div>
                          );
                        })}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  width="100%"
                  cellSpacing="0"
                  cellPadding="0"
                  style={{ marginTop: "30px", marginBottom: "10px" }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <h5
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "2px",
                            marginBottom: "8px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20px"
                            viewBox="0 0 512 512"
                            style={{ marginRight: "10px" }}
                          >
                            <path d="M128 480h256V80c0-26.5-21.5-48-48-48H176c-26.5 0-48 21.5-48 48v400zm64-384h128v32H192V96zm320 80v256c0 26.5-21.5 48-48 48h-48V128h48c26.5 0 48 21.5 48 48zM96 480H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48h48v352z" />
                          </svg>
                          <span
                            style={{
                              fontSize: "28px",
                              color: "#000",
                              fontWeight: "500",
                            }}
                          >
                            Education
                          </span>
                        </h5>

                        {profileData?.postGraduation.map((val) => {
                          return (
                            <div key={val.id} style={{ paddingLeft: "30px" }}>
                              <span
                                style={{
                                  fontSize: "20px",
                                  color: "#000",
                                  fontWeight: "500",
                                  lineHeight: "1",
                                }}
                              >
                                {val.university_name}
                              </span>
                              <span
                                style={{
                                  fontSize: "18px",
                                  color: "rgb(42 42 42)",
                                  display: "block",
                                  lineHeight: "1",
                                  marginBottom: "5px",
                                }}
                              >
                                {/* Postgraduate degree, {val.field_of_study_name} */}
                                Grade ({val.grade_name})
                              </span>

                              <span
                                style={{
                                  fontSize: "16px",
                                  color: "rgb(129 127 127)",
                                  lineHeight: "1",
                                  display: "block",
                                  marginBottom: "10px",
                                }}
                              >
                                {val.start_year} - {val.end_year}
                              </span>
                            </div>
                          );
                        })}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {profileData?.university?.map((val) => {
                          return (
                            <div key={val.id} style={{ paddingLeft: "30px" }}>
                              <span
                                style={{
                                  fontSize: "20px",
                                  color: "#000",
                                  fontWeight: "500",
                                  lineHeight: "1",
                                }}
                              >
                                {val.university_name}
                              </span>
                              <span
                                style={{
                                  fontSize: "18px",
                                  color: "rgb(42 42 42)",
                                  display: "block",
                                  lineHeight: "1",
                                  marginBottom: "5px",
                                }}
                              >
                                {/* Postgraduate degree, {val.field_of_study_name} */}
                                Grade ({val.grade_name})
                              </span>
                              <span
                                style={{
                                  fontSize: "16px",
                                  color: "rgb(42 42 42)",
                                  lineHeight: "1",
                                  display: "block",
                                  marginBottom: "5px",
                                }}
                              >
                                Undergraduate degree, {val.degree_level_name}
                              </span>
                              <span
                                style={{
                                  fontSize: "16px",
                                  color: "rgb(149 147 147)",
                                  lineHeight: "1",
                                  display: "block",
                                  marginBottom: "20px",
                                }}
                              >
                                {val.start_year} - {val.end_year}
                              </span>
                            </div>
                          );
                        })}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div style={{ paddingLeft: "30px" }}>
                          <span
                            style={{
                              fontSize: "20px",
                              color: "#000",
                              fontWeight: "500",
                              lineHeight: "1",
                            }}
                          >
                            {profileData?.education?.high_school_name}
                          </span>
                          <span
                            style={{
                              fontSize: "16px",
                              color: "rgb(42 42 42)",
                              lineHeight: "1",
                              display: "block",
                              marginBottom: "10px",
                            }}
                          >
                            High School,{" "}
                            {
                              profileData?.education
                                ?.graduation_certificate_name
                            }
                          </span>

                          <span
                            style={{
                              fontSize: "16px",
                              color: "rgb(149 147 147)",
                              lineHeight: "1",
                              display: "block",
                              marginBottom: "20px",
                            }}
                          >
                            {profileData?.education?.graduation_year}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table
                  width="100%"
                  cellSpacing="0"
                  cellPadding="0"
                  style={{ marginTop: "30px", marginBottom: "10px" }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <h5
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "2px",
                            marginBottom: "8px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20px"
                            viewBox="0 0 512 512"
                            style={{ marginRight: "10px" }}
                          >
                            <path d="M128 480h256V80c0-26.5-21.5-48-48-48H176c-26.5 0-48 21.5-48 48v400zm64-384h128v32H192V96zm320 80v256c0 26.5-21.5 48-48 48h-48V128h48c26.5 0 48 21.5 48 48zM96 480H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48h48v352z" />
                          </svg>
                          <span
                            style={{
                              fontSize: "28px",
                              color: "#000",
                              fontWeight: "500",
                            }}
                          >
                            Certification
                          </span>
                        </h5>
                        {profileData?.certification?.map((val) => {
                          return (
                            <div key={val.id} style={{ paddingLeft: "30px" }}>
                              <span
                                style={{
                                  fontSize: "20px",
                                  color: "#000",
                                  fontWeight: "500",
                                  lineHeight: "1",
                                }}
                              >
                                {val.organisation_name}
                              </span>
                              <span
                                style={{
                                  fontSize: "18px",
                                  color: "rgb(42 42 42)",
                                  display: "block",
                                  lineHeight: "1",
                                  marginBottom: "5px",
                                }}
                              >
                                {val.field_of_study_name}
                              </span>
                              <span
                                style={{
                                  fontSize: "16px",
                                  color: "rgb(129 127 127)",
                                  lineHeight: "1",
                                  display: "block",
                                  marginBottom: "10px",
                                }}
                              >
                                {val.graduation_year}
                              </span>
                            </div>
                          );
                        })}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>

              <td align="left" style={{ textAlign: "left", width: "300px" }}>
                <table
                  width="100%"
                  cellSpacing="0"
                  cellPadding="0"
                  style={{ marginTop: "30px", marginBottom: "10px" }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <h5
                          style={{
                            fontSize: "24px",
                            color: "#000",
                            fontWeight: "500",
                          }}
                        >
                          Detail
                        </h5>
                        <div style={{ paddingLeft: "0px" }}>
                          <p>
                            {profileData?.general?.current_area},{" "}
                            {profileData?.general?.current_city_name},{" "}
                            {profileData?.general?.current_country_name}
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  width="100%"
                  cellSpacing="0"
                  cellPadding="0"
                  style={{ marginTop: "30px", marginBottom: "10px" }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <h5
                          style={{
                            fontSize: "24px",
                            color: "#000",
                            fontWeight: "500",
                          }}
                        >
                         Additional Detail
                        </h5>
                        <div style={{ paddingLeft: "0px" }}>
                          <p>
                          <span>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_528_23107)">
                                <path
                                  d="M19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z"
                                  stroke="#7C8493"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M3 7L12 13L21 7"
                                  stroke="#7C8493"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_528_23107">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          {" "}
                            {profileData?.general?.contact_email}
                          </p>
                        </div>
                        <div style={{ paddingLeft: "0px" }}>
                          <p>
                          <span>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_528_23115)">
                                <path
                                  d="M16 4H8C7.44772 4 7 4.44772 7 5V19C7 19.5523 7.44772 20 8 20H16C16.5523 20 17 19.5523 17 19V5C17 4.44772 16.5523 4 16 4Z"
                                  stroke="#7C8493"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M11 5H13"
                                  stroke="#7C8493"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M12 17V17.01"
                                  stroke="#25324B"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_528_23115">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                            {profileData?.general?.contact_mobile}
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  width="100%"
                  cellSpacing="0"
                  cellPadding="0"
                  style={{ marginTop: "30px", marginBottom: "10px" }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <h5
                          style={{
                            fontSize: "24px",
                            color: "#000",
                            fontWeight: "500",
                          }}
                        >
                          Skills
                        </h5>
                        <div style={{ paddingLeft: "0px" }}>
                          <ul style={{ marginLeft: "-30px" }}>
                            {profileData?.skills?.map((val) => {
                              return (
                                <li
                                  key={val.id}
                                  style={{
                                    fontWeight: "500",
                                    marginBottom: "8px",
                                    listStyle: "none",
                                  }}
                                >
                                  {val?.skill_name}
                                  <div
                                    className="progress"
                                    style={{ height: "5px" }}
                                  >
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      aria-valuenow="70"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{
                                        width: levelPercentage[val.level],
                                      }}
                                    >
                                      <span className="sr-only">
                                        70% Complete
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  width="100%"
                  cellSpacing="0"
                  cellPadding="0"
                  style={{ marginTop: "30px", marginBottom: "10px" }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <h5
                          style={{
                            fontSize: "24px",
                            color: "#000",
                            fontWeight: "500",
                          }}
                        >
                          Language
                        </h5>
                        <div style={{ paddingLeft: "0px" }}>
                          <ul style={{ marginLeft: "-30px" }}>
                            {profileData?.languages?.map((val) => {
                              return (
                                <li
                                  key={val.id}
                                  style={{
                                    fontWeight: "500",
                                    marginBottom: "8px",
                                    listStyle: "none",
                                  }}
                                >
                                  {val.language_name}
                                  <div
                                    className="progress"
                                    style={{ height: "5px" }}
                                  >
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      aria-valuenow="70"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{
                                        width: levelPercentage[val.level],
                                      }}
                                    >
                                      <span className="sr-only">
                                        70% Complete
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DownloadCv;
