import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { arrayMoveImmutable } from "array-move";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";

import {
  View,
  Edit,
  Delete,
  MakeDeactivate,
  MakeActivate,
  ChangePassword,
  SendCreds,
  Wallet,
  Login,
  SystemManagements,
  FilledStar,
  EmptyStar,
  Download,
  Unverify,
  Verify,
} from "../../util/Svg";
import { API } from "../../constant/api";

const DragHandle = sortableHandle(() => (
  <span className="drag">
    <SystemManagements />
  </span>
));
const SortableContainer = sortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});

const SortableItem = sortableElement(
  ({
    data,
    tableData,
    links,
    onlyDate,
    page,
    date_format,
    date_time_format,
    renderAs,
  }) => {
    const { role } = useSelector((state) => state.auth);

    return (
      <>
        <tr key={`item-${data}`} index={data}>
          <td className="pl-0 py-5">
            <DragHandle />
          </td>
          {tableData.map((tData, index) => {
            let value;
            if (tData == "description") {
              value = (
                <p
                  dangerouslySetInnerHTML={{
                    __html: truncate(data[tData]),
                  }}
                ></p>
              );
            } else if (Object.keys(onlyDate).includes(tData)) {
              if (onlyDate[tData] === "date") {
                value = <Moment format={date_format}>{data[tData]}</Moment>;
              } else if (onlyDate[tData] === "dateTime") {
                value = (
                  <Moment format={date_time_format}>{data[tData]}</Moment>
                );
              }
            } else if (Object.keys(renderAs).includes(tData)) {
              value = renderAs[tData](data[tData], data.id, data);
            } else if (tData == "isActive") {
              if (
                data[tData] == "true" ||
                data[tData] == true ||
                data[tData] == 1
              ) {
                value = (
                  <span className="label label-lg label-light-success label-inline">
                    Activated
                  </span>
                );
              } else {
                value = (
                  <span className="label label-lg label-light-danger label-inline">
                    Deactivated
                  </span>
                );
              }
            } else if (tData == "message") {
              value = truncate(data[tData]);
            } else if (tData == "amount") {
              value = `$${data[tData]}`;
            } else if (tData == "image") {
              value = (
                <img
                  src={`${API.PORT}/${data[tData]}`}
                  alt=""
                  data-fancybox
                  height={50}
                  width={80}
                  style={{ cursor: "pointer" }}
                />
              );
            } else if (tData === "answer") {
              value = (
                <p
                  dangerouslySetInnerHTML={{
                    __html: truncate(data[tData]),
                  }}
                ></p>
              );
            }

            // else if (tData === "category") {
            //   if(data[tData] == "talent"){
            //     value = "Job Hunter";
            //   }else{
            //     value = data[tData];
            //   }
            // }
            else {
              value = data[tData];
            }

            return (
              <td key={index} className="py-5">
                <div className="d-flex align-items-center">
                  <div className="text-dark-75 mb-1  font-size-lg">{value}</div>
                </div>
              </td>
            );
          })}

          <td className="text-left pr-2" style={{ whiteSpace: "nowrap" }}>
            {links?.map((link, index) => {
              let name = link.name;
              let svg;

              if (name == "Edit") {
                svg = <Edit />;
              } else if (name == "Delete") {
                svg = <Delete />;
              } else if (name == "View") {
                svg = <View />;
              } else if (
                name == "Deactivate" &&
                data.isActive != "true" &&
                data.isActive != true &&
                data.isActive != 1
              ) {
                svg = <MakeActivate />;
              } else if (
                name == "Activate" &&
                data.isActive != "false" &&
                data.isActive != false &&
                data.isActive != 0
              ) {
                svg = <MakeDeactivate />;
              } else if (name == "ChangePassword") {
                svg = <ChangePassword />;
              } else if (name == "SendCreds") {
                svg = <SendCreds />;
              } else if (name == "Wallet") {
                svg = <Wallet />;
              } else if (name == "Login") {
                svg = <Wallet />;
              }

              if (link.isLink) {
                return (
                  <Link
                    key={index}
                    to={
                      link.extraData
                        ? { pathname: `${link.to}/${data.id}`, page }
                        : `${link.to}/${data.id}`
                    }
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    data-container="body"
                    data-boundary="window"
                    title={link.title || name}
                    data-original-title={link.title || name}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      {svg}
                    </span>
                  </Link>
                );
              } else {
                return (
                  <Fragment key={index}>
                    {svg && (
                      <a
                        key={index}
                        className={`btn btn-icon btn-light mr-2 ${
                          name === "Delete"
                            ? "btn-hover-danger confirmDelete"
                            : "btn-hover-primary"
                        }  btn-sm `}
                        data-toggle="tooltip"
                        data-placement="top"
                        data-container="body"
                        data-boundary="window"
                        title={link.title || name}
                        data-original-title={link.title || name}
                      >
                        <span
                          onClick={() => link.click(data.id, data)}
                          className={`svg-icon svg-icon-md ${
                            name === "Delete"
                              ? "svg-icon-danger"
                              : "svg-icon-primary"
                          }`}
                        >
                          {svg}
                        </span>
                      </a>
                    )}
                  </Fragment>
                );
              }
            })}
          </td>
        </tr>
      </>
    );
  }
);

const truncate = (input) =>
  input.length > 200 ? `${input.substring(0, 200)}...` : input;

const Table = ({
  mainData,
  tableHeading,
  tableData,
  links,
  sortingHandler,
  currentSort,
  onlyDate,
  page,
  dontShowSort = [],
  renderAs = {},
  sorting,
  changeOrderHandler,
  // status,
}) => {
  const [filteredLinks, setFilteredLinks] = useState(links);
  const { permissions, user_role_id } = useSelector((store) => store.auth);

  useEffect(() => {
    if (user_role_id != 1) {
      let filtered = filteredLinks.filter((elem) => {
        let exists = false;
        elem.key.forEach((v) => {
          if (!!permissions[v]) {
            exists = true;
          }
        });
        return exists;
      });
      setFilteredLinks(filtered);
    }
  }, []);

  const { date_format, date_time_format } = useSelector(
    (state) => state.setting
  );

  const [items, setItems] = useState([...mainData]);

  useEffect(() => {
    setItems([...mainData]);
  }, [mainData]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    // console.log(oldIndex, newIndex);
    let newArray = arrayMoveImmutable(items, oldIndex, newIndex);
    setItems(newArray);
    if (changeOrderHandler) {
      let newOrder = {};
      newArray.forEach((val, i) => {
        newOrder[val.id] = i;
      });
      changeOrderHandler(newOrder);
    }
  };

  return (
    <div className="table-responsive">
      <table
        className="table dataTable table-head-custom table-head-bg table-borderless table-vertical-center"
        id="taskTable"
      >
        <thead>
          <tr className="text-uppercase" style={{ whiteSpace: "nowrap" }}>
            {sorting && <th>ORDER</th>}
            {tableHeading.map((heading, index) => (
              <th
                onClick={() => sortingHandler(heading)}
                key={index}
                className={`${
                  currentSort.sortBy == heading
                    ? `sorting_${currentSort.order}`
                    : dontShowSort.includes(heading)
                    ? ""
                    : "sorting"
                }`}
                style={{
                  width:
                    heading === "answer" || heading === "description"
                      ? "400px"
                      : "",
                }}
              >
                <a className="no_sort">{heading}</a>
              </th>
            ))}
            {filteredLinks && filteredLinks.length > 0 ? (
              <th className="text-left ActionText">Action</th>
            ) : null}
          </tr>
        </thead>

        {sorting && mainData.length > 0 ? (
          <SortableContainer useDragHandle onSortEnd={onSortEnd}>
            {items.map((data, i) => (
              <SortableItem
                key={`item-${i}`}
                index={i}
                data={data}
                tableData={tableData}
                onlyDate={onlyDate}
                links={filteredLinks}
                page={page}
                date_format={date_format}
                date_time_format={date_time_format}
                renderAs={renderAs}
              />
            ))}
          </SortableContainer>
        ) : (
          <tbody>
            {mainData.length > 0 &&
              mainData.map((data) => (
                <tr key={data.id}>
                  {tableData.map((tData, index) => {
                    let value;
                    if (tData == "description") {
                      value = (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: truncate(data[tData]),
                          }}
                        ></p>
                      );
                    } else if (Object.keys(onlyDate).includes(tData)) {
                      if (onlyDate[tData] === "date") {
                        value = (
                          <Moment format={date_format}>{data[tData]}</Moment>
                        );
                      } else if (onlyDate[tData] === "dateTime") {
                        value = (
                          <Moment format={date_time_format}>
                            {data[tData]}
                          </Moment>
                        );
                      }
                    } else if (Object.keys(renderAs).includes(tData)) {
                      value = renderAs[tData](data[tData], data.id, data);
                    } else if (tData == "isActive") {
                      if (
                        data[tData] == "true" ||
                        data[tData] == true ||
                        data[tData] == 1
                      ) {
                        value = (
                          <span className="label label-lg label-light-success label-inline">
                            Activated
                          </span>
                        );
                      } else {
                        value = (
                          <span className="label label-lg label-light-danger label-inline">
                            Deactivated
                          </span>
                        );
                      }
                    } else if (tData == "message") {
                      value = truncate(data[tData]);
                    } else if (tData == "amount") {
                      value = `$${data[tData]}`;
                    } else if (tData == "image") {
                      value = (
                        <img
                          src={`${API.PORT}/${data[tData]}`}
                          alt=""
                          data-fancybox
                          height={50}
                          width={80}
                          style={{ cursor: "pointer" }}
                        />
                      );
                    } else if (tData === "answer") {
                      value = (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: truncate(data[tData]),
                          }}
                        ></p>
                      );
                    }
                    // else if (tData == "type") {
                    //     if(data[tData] == "talent"){
                    //       value = "Job Hunter";
                    //     }else{
                    //       value = data[tData];
                    //     }
                    //   }
                    else {
                      // console.log("tData",tData)
                      value = data[tData];
                    }

                    return (
                      <td key={index} className="py-5">
                        <div className="d-flex align-items-center">
                          <div className="text-dark-75 mb-1  font-size-lg">
                            {value}
                            {/* <span className="False_text">False</span>  
                     <span className="true_text">True</span>   */}

                            {/* {tData === "description" ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: truncate(data[tData]),
                              }}
                            ></p>
                          ) : tData === "createdAt" ? (
                            onlyDate ? (
                              <Moment format={date_format}>
                                {data[tData]}
                              </Moment>
                            ) : (
                              <Moment format={date_time_format}>
                                {data[tData]}
                              </Moment>
                            )
                          ) : tData === "isActive" ? (
                            data[tData] == "true" ? (
                              <span className="label label-lg label-light-success label-inline">
                                Activated
                              </span>
                            ) : (
                              <span className="label label-lg label-light-danger label-inline">
                                Deactivated
                              </span>
                            )
                          ) : (
                            data[tData]
                          )} */}
                          </div>
                        </div>
                      </td>
                    );
                  })}

                  <td
                    className="text-left pr-2"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {filteredLinks?.map((link, index) => {
                      let name = link.name;
                      let svg;

                      if (name == "Edit") {
                        svg = <Edit />;
                      } else if (name == "Delete") {
                        svg = <Delete />;
                      } else if (name == "View") {
                        svg = <View />;
                      } else if (
                        name == "Deactivate" &&
                        data.isActive != "true" &&
                        data.isActive != true &&
                        data.isActive != 1
                      ) {
                        svg = <MakeActivate />;
                      } else if (
                        name == "Activate" &&
                        data.isActive != "false" &&
                        data.isActive != false &&
                        data.isActive != 0
                      ) {
                        svg = <MakeDeactivate />;
                      }
                      else if (
                        name == "Unverify" &&
                        data.admin_verifying != "true" &&
                        data.admin_verifying != true &&
                        data.admin_verifying != 1
                      ) {
                        svg = <Unverify />;
                      } else if (
                      
                        name == "Verify" &&
                        data.admin_verifying != "false" &&
                        data.admin_verifying != false &&
                        data.admin_verifying != 0
                      ) {
                        svg = <Verify />;
                      }
                      else if (name == "ChangePassword") {
                        svg = <ChangePassword />;
                      } else if (name == "SendCreds") {
                        svg = <SendCreds />;
                      } else if (name == "Wallet") {
                        svg = <Wallet />;
                      } else if (name == "Login") {
                        svg = <Wallet />;
                      } else if (
                        name == "Recommended" &&
                        data.is_recommended == 1
                      ) {
                        svg = <FilledStar />;
                      } else if (
                        name == "Not Recommended" &&
                        data.is_recommended != 1
                      ) {
                        svg = <EmptyStar />;
                      } else if (name == "DownloadCv") {
                        svg = <Download />;
                      }

                      if(name == "DownloadCv" && !data.isAvailableForDownload){
                        return null
                      }

                      if (link.isLink) {
                        return (
                          <Link
                            key={index}
                            to={
                              link.extraData
                                ? { pathname: `${link.to}/${data.id}`, page }
                                : `${link.to}/${data.id}`
                            }
                            className="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
                            data-toggle="tooltip"
                            data-placement="top"
                            data-container="body"
                            data-boundary="window"
                            title={link.title || name}
                            data-original-title={link.title || name}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              {svg}
                            </span>
                          </Link>
                        );
                      } else {
                        return (
                          <Fragment key={index}>
                            {svg && (
                              <a
                                key={index}
                                className={`btn btn-icon btn-light mr-2 ${
                                  name === "Delete"
                                    ? "btn-hover-danger confirmDelete"
                                    : "btn-hover-primary"
                                }  btn-sm `}
                                data-toggle="tooltip"
                                data-placement="top"
                                data-container="body"
                                data-boundary="window"
                                title={link.title || name}
                                data-original-title={link.title || name}
                                onClick={() =>
                                  link.click(data.id, data, mainData)
                                }
                              >
                                <span
                              
                                  className={`svg-icon svg-icon-md ${
                                    name === "Delete"
                                      ? "svg-icon-danger"
                                      : "svg-icon-primary"
                                  }`}
                                >
                                  {svg}
                                </span>
                              </a>
                            )}
                          </Fragment>
                        );
                      }
                    })}
                  </td>
                </tr>
              ))}
          </tbody>
        )}
        {mainData.length == 0 && (
          <tbody>
            <tr>
              <td colSpan="6" style={{ textAlign: "center" }}>
                {" "}
                {"Record not found"}
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default Table;
