import {
  Dashboard,
  Seeker,
  CustomerManagement,
  SubAdminManagement,
  SystemManagements,
  Setting,
  WarehouseManagement,
} from "./Svg";

export const menu = [
  {
    name: "Dashboard",
    path: "/",
    Svg: Dashboard,
    subMenu: [],
    highlight: ["/my-profile", "/change-password"],
    subHighlight: [],
    key: ["0"],
  },
  {
    name: "Users",
    Svg: CustomerManagement,
    subMenu: [
      {
        name: "Candidates",
        path: "/candidate",
        Svg: Seeker,
        subMenu: [],
        highlight: ["/candidate/add"],
        subHighlight: [
          "/candidate/view",
          "/candidate/edit",
          "/candidate/change-password",
        ],
        key: ["10_1"],
      },
    ],
    highlight: ["/candidate", "/candidate/add"],
    subHighlight: [
      "/candidate/view",
      "/candidate/edit",
      "/candidate/change-password",
    ],
    key: ["10"],
  },

  {
    name: "Sub Admin Management",
    Svg: SystemManagements,
    subMenu: [
      {
        name: "Sub - Admin",
        path: "/sub-admin",
        highlight: ["/sub-admin/add"],
        subHighlight: ["/sub-admin/view", "/sub-admin/edit"],
        key: ["20_1"],
      },
      {
        name: "Admin Roles",
        path: "/admin-roles",
        highlight: ["/admin-roles/add"],
        subHighlight: ["/admin-roles/view", "/admin-roles/edit"],
        key: ["30_1"],
      },
    ],
    highlight: ["/sub-admin", "/sub-admin/add"],
    subHighlight: ["/sub-admin/view", "/sub-admin/edit"],
    key: ["20", "30"],
  },

  {
    name: "System Management",
    Svg: SystemManagements,
    subMenu: [
      {
        name: "CMS Pages",
        path: "/cms",
        highlight: ["/cms/add"],
        subHighlight: ["/cms/view", "/cms/edit"],
        key: ["40_1"],
      },

      {
        name: "Email Templates",
        path: "/email-template",
        highlight: ["/email-template/add"],
        subHighlight: ["/email-template/edit"],
        key: ["50_1"],
      },
      {
        name: "Email Logs",
        path: "/email-logs",
        highlight: [],
        subHighlight: [],
        key: ["60_1"],
      },
    ],
    highlight: ["/setting/prefix/Payment", "/system-images/add"],
    subHighlight: ["/system-images/edit", "/seo-page/edit"],
    key: ["40", "50", "60"],
  },
  {
    name: "Contact Queries",
    path: "/contact",
    Svg: SubAdminManagement,
    subMenu: [],
    highlight: ["/contact"],
    subHighlight: ["/contact/view"],
    key: ["71_1"],
  },
  {
    name: "Newsletter Subscribers",
    path: "/newsletter",
    Svg: CustomerManagement,
    subMenu: [],
    highlight: ["/newsletter"],
    subHighlight: [],
    key: ["72_1"],
  },
  {
    name: "Master Management",
    Svg: WarehouseManagement,
    subMenu: [
      {
        name: "Job Industries",
        path: "/job-industry",
        highlight: [],
        subHighlight: [],
        key: ["110_1"],
      },
      {
        name: "Job Categories",
        path: "/job-category",
        highlight: [],
        subHighlight: [],
        key: ["120_1"],
      },
      {
        name: "Job Types",
        path: "/job-type",
        highlight: [],
        subHighlight: [],
        key: ["130_1"],
      },
      {
        name: "Study Topics",
        path: "/study-topic",
        highlight: [],
        subHighlight: [],
        key: ["140_1"],
      },
      {
        name: "Study Fields",
        path: "/study-field",
        highlight: [],
        subHighlight: [],
        key: ["150_1"],
      },
      {
        name: "Grade",
        path: "/grade",
        highlight: [],
        subHighlight: [],
        key: ["160_1"],
      },
      {
        name: "Graduation Certificates",
        path: "/graduation-certificate",
        highlight: [],
        subHighlight: [],
        key: ["170_1"],
      },
      {
        name: "Degree Level",
        path: "/degree-level",
        highlight: [],
        subHighlight: [],
        key: ["180_1"],
      },
      {
        name: "Skills",
        path: "/skills",
        highlight: [],
        subHighlight: [],
        key: ["170_1"],
      },
      {
        name: "Languages",
        path: "/languages",
        highlight: [],
        subHighlight: [],
        key: ["200_1"],
      },
      {
        name: "Career Level",
        path: "/career-level",
        highlight: [],
        subHighlight: [],
        key: ["210_1"],
      },
      {
        name: "Status",
        path: "/status",
        highlight: [],
        subHighlight: [],
        key: ["220_1"],
      },
      {
        name: "Lives In",
        path: "/lives-in",
        highlight: [],
        subHighlight: [],
        key: ["230_1"],
      },
      {
        name: "Post-Graduation Degree Level",
        path: "/post-degree-level",
        highlight: [],
        subHighlight: [],
        key: ["240_1"],
      },
    ],

    highlight: ["/website", "/website/add", "/employee-count/add"],
    subHighlight: ["/website/edit", "/employee-count/edit"],
    key: ["110"],
  },
  {
    name: "Settings",
    Svg: Setting,
    subMenu: [
      {
        name: "Site Setting",
        path: "/setting/prefix/Site",
        highlight: [],
        subHighlight: [],
        key: ["80_4"],
      },
      {
        name: "Reading Setting",
        path: "/setting/prefix/Reading",
        highlight: [],
        subHighlight: [],
        key: ["80_5"],
      },
      {
        name: "Social Setting",
        path: "/setting/prefix/Social",
        highlight: [],
        subHighlight: [],
        key: ["80_6"],
      },
      {
        name: "Contact Setting",
        path: "/setting/prefix/Contact",
        highlight: [],
        subHighlight: [],
        key: ["80_7"],
      },
    ],
    highlight: ["/setting", "/setting/add", "/employee-count/add"],
    subHighlight: ["/setting/edit", "/employee-count/edit"],
    key: ["80"],
  },
  {
    name: "Website Management",
    Svg: WarehouseManagement,
    subMenu: [
      {
        name: "Blocks",
        path: "/block",
        highlight: [],
        subHighlight: [],
        key: ["90_1"],
      },
      {
        name: "Testimonials",
        path: "/testimonial",
        Svg: Seeker,
        subMenu: [],
        highlight: ["/testimonial/add"],
        subHighlight: ["/testimonial/edit", "/testimonial/change-password"],
        key: ["100_1"],
      },
    ],
    highlight: ["/website", "/website/add", "/employee-count/add"],
    subHighlight: ["/website/edit", "/employee-count/edit"],
    key: ["90", "100"],
  },
 
];
