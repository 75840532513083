import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import {
  RenderInputFields,
  SubmitButton,
  Input,
  CKEditorInput,
} from "../../components/Form/Form";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    clearErrors,
  } = useForm();

    const ckEditorRef = useRef();

  const { response, request } = useRequest();

  const history = useHistory();

  useEffect(() => {
    document.title = "Add Block - Native";
  }, []);

  useEffect(() => {
    if (response) {
      toast.success("Block has been added successfully.");
      history.push("/block");
    }
  }, [response]);

  const onSubmit = (data) => {
    console.log(data);

    let { page, title, image, description } = data;
 
    let formData = new FormData();

    formData.append("page", page);
    formData.append("title", title);
    formData.append("description", description);

    if (image && image[0]) {
      formData.append("image", image[0]);
    }
  
    request("POST", "block", formData);
  };

  const InputFields = [
    [
      {
        Component: Input,
        type: "text",
        label: "Page",
        name: "page",
        colClass: "col-lg-6",
        registerFields: {
          required: true,
        },
      },
      {
        Component: Input,
        type: "text",
        label: "Title",
        name: "title",
        colClass: "col-lg-6",
        registerFields: {
          required: true,
        },
      },
      {
        Component: Input,
        label: "Image",
        type: "file",
        name: "image",
        colClass: "col-lg-6",
        registerFields: {
          //   required: true,
        },
      },
    ],
    [
      {
        Component: CKEditorInput,
        colClass: "col-xl-12",
        label: "Description",
        name: "description",
        registerFields: {
          required: false,
        },
        getValues,
        setValue,
        trigger,
        inputData: {
          onInstanceReady: (editor) => {
            ckEditorRef.current = editor;
          },
        },
        clearErrors,
        isEdit: false,
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add Block"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/block", name: "Back To Blocks" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-12">
                  {/* <h3 className="mb-10 font-weight-bold text-dark">
                    Add New Block
                  </h3> */}

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
