export const API = {
  PORT:
    process.env.NODE_ENV === "development"
      ? "https://ns.native-career.com"
      : "https://ns.native-career.com",
};

export const BASEURL = {
  PORT:
    process.env.NODE_ENV === "development"
      ? "https://ns.native-career.com"
      : "https://ns.native-career.com",
};

export const FRONTENDURL =
  process.env.NODE_ENV === "development"
    ? "https://nativens.stage04.obdemo.com"
    : "https://ns.native-career.com";

export const ADMINURL =
  process.env.NODE_ENV == "development"
    ? "https://ns.native-career.com"
    : "https://ns.native-career.com";
