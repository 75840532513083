import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { API } from "../../constant/api";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import {
  Input,
  RenderInputFields,
  SelectInput,
  SubmitButton,
  Textarea,
} from "../../components/Form/Form";

const Edit = (props) => {
  const { id: testimonialId } = props.match.params;
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = useForm();

  const [oldImage, setOldImage] = useState("");
  const { response: fetchTestimonialRes, request: fetchTestimonialReq } =
    useRequest();
  const { response, request } = useRequest();

  useEffect(() => {
    if (testimonialId) {
      fetchTestimonialReq("GET", `testimonial/${testimonialId}`);
      document.title = "Edit Testimonial - Native";
    }
  }, [testimonialId]);

  useEffect(() => {
    if (fetchTestimonialRes) {
      const { name, designation, comment, image } =
        fetchTestimonialRes.testimonial;
      setValue("name", name);
      setValue("designation", designation);
      setValue("comment", comment);
      if (image) {
        setOldImage(image);
      }
    }
  }, [fetchTestimonialRes]);

  useEffect(() => {
    if (response) {
      toast.success("Testimonial has been updated successfully.");
      history.push("/testimonial");
    }
  }, [response]);

  const onSubmit = (data) => {
    let { image, name, designation, comment } = data;

    let formData = new FormData();
    formData.append("id", testimonialId);
    formData.append("name", name);
    formData.append("designation", designation);
    formData.append("comment", comment);

    if (image && image[0]) {
      if (image[0].size > 3030000) {
        setError("image", {
          type: "size",
          message: "Image should be less then 3 MB",
        });
        return;
      }
      formData.append("image", image[0]);
    }

    request("PUT", "testimonial", formData);
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "Image",
        type: "file",
        name: "image",
        registerFields: {
          // required: true,
        },
        children: oldImage ? (
          <img data-fancybox src={`${API.PORT}/${oldImage}`} width="100px" />
        ) : (
          ""
        ),
      },
      {
        Component: Input,
        label: "Name",
        type: "text",
        name: "name",
        registerFields: {
          required: {
            value: true,
          },
          // pattern: {
          //   //value: /^[a-zA-Z]*$/,
          //   message: "Legal name must contain only alphabets.",
          // },
        },
        registerFieldsFeedback: {
          pattern: "Legal name can only contain alphabets.",
        },
      },
      {
        Component: Input,
        label: "Designation",
        type: "text",
        name: "designation",
        registerFields: {
          required: true,
        },
      },
      {
        Component: Textarea,
        label: "Comment",
        type: "textarea",
        name: "comment",
        registerFields: {
          required: true,
          //pattern: /^[A-Za-z ]+$/,
        },
        // registerFieldsFeedback: {
        //   pattern: "Name can only contain letters.",
        // },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Testimonial"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/testimonial" /*backPageNum: page */ },
            name: "Back To Testimonial",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark"></h3>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
