import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { API } from "../../constant/api";
import { SubTab } from "../Cms/TabNInput";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { Textarea, RenderInputFields, SubmitButton } from "../../components/Form/Form";
import { toast } from "react-toastify";

const Activated = () => (
  <span className="label label-lg label-light-success label-inline">
    Yes
  </span>
);

const Deactivated = () => (
  <span className="label label-lg label-light-danger label-inline">
    No
  </span>
);

const ViewOne = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
    setValue,
    control,
  } = useForm();
  const { id: contactId } = props.match.params;
  const [contactData, setContactData] = useState([]);
  const [isAlreadyReplied, setIsAlreadyReplied] = useState(false);
  const { response: responseUserData, request: requestUserData } = useRequest();
  const { response: sendReplyRes, request: sendReplyReq } = useRequest();
  const { date_time_format } = useSelector((state) => state.setting);
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    requestUserData("GET", `contact/${contactId}`);

    document.title = "View Contact Query- Native";
  }, []);

  useEffect(() => {
    if (responseUserData) {
      const {
        name,
        email,
        company_name,
        company_size,
        is_replied,
        reply,
        question,
        created_at,
      } = responseUserData.contact;
      setContactData([
        { title: "Name", value: name },
        { title: "Email", value: email },
        { title: "Company Name", value: company_name },
        { title: "Company Size", value: company_size },
        { title: "Question", value: question },
        {
          title: "Requested On",
          // value: <Moment format={date_format}>{created_at}</Moment>,

          value: <Moment format={date_time_format}>{created_at}</Moment>,
        },
        { title: "Is Replied", value: is_replied ? <Activated/> : <Deactivated/> },
      ]);
      setValue("reply", reply || "");
      setIsAlreadyReplied(is_replied ? true : false);
    }
  }, [responseUserData]);

  const currentTabIndexHandler = (idx) => {
    setTabIndex(idx);
  };

  const onSubmit = (data) => {    
    sendReplyReq("PUT", "contact/reply", {
      reply: data.reply,
      id : contactId,
    });
  };

  useEffect(() => {
    if(sendReplyRes && sendReplyRes.status){
      toast.success(sendReplyRes.message);
      history.push("/contact");      
    }
  }, [sendReplyRes])

  const InputFields = [
    [
      {
        Component: Textarea,
        label: "Reply Message",
        type: "text",
        name: "reply",
        registerFields: {},
        inputData: {
          disabled: isAlreadyReplied,
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="View Contact Query"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/contact", name: "Back To Contact Queries" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card card-custom gutter-b">
            <div className="card-header card-header-tabs-line">
              <div className="card-toolbar">
                <ul
                  className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                  role="tablist"
                >
                  {["Query Data", "Reply"].map((data, index) => (
                    <SubTab
                      key={index}
                      name={data}
                      index={index}
                      onClick={currentTabIndexHandler}
                    />
                  ))}
                </ul>
              </div>
            </div>

            <div
              style={{
                display: tabIndex === 0 ? "block" : "none",
              }}
              className="card-body px-0"
            >
              <div className="tab-content px-10">
                <div
                  className={`tab-pane ${tabIndex === 0 ? "active" : ""}`}
                  id={`kt_apps_contacts_view_tab_3`}
                  role="tabpanel"
                >
                  {contactData.length > 0 &&
                    contactData.map((user, index) => (
                      <div key={index} className="form-group row my-2">
                        <label className="col-4 col-form-label">
                          {user.title}
                        </label>
                        <div className="col-8">
                          <span className="form-control-plaintext font-weight-bolder">
                            {user.value}
                          </span>
                        </div>
                      </div>
                    ))}

                  <div className="row"></div>
                </div>
              </div>
            </div>

            <div
              style={{
                display: tabIndex === 1 ? "block" : "none",
              }}
              className="card-body px-0"
            >
              <div className="tab-content px-10">
                <div
                  className={`tab-pane ${tabIndex === 1 ? "active" : ""}`}
                  id={`kt_apps_contacts_view_tab_3`}
                  role="tabpanel"
                >
                  <div className="col-xl-10">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <RenderInputFields
                        InputFields={InputFields}
                        errors={errors}
                        register={register}
                      />
                      <div className="row"></div>
                      {!isAlreadyReplied && (
                        <SubmitButton
                          handleSubmit={handleSubmit}
                          onSubmit={onSubmit}
                          name="Submit"
                        />
                      )}
                    </form>
                  </div>

                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOne;
