import Login from "../pages/Login/Login";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import SetPassword from "../pages/SetPassword/SetPassword.js";

import Dashboard from "../pages/Dashboard/Dashboard";
import ChangePassword from "../pages/ChangePassword/ChangePassword";
import Profile from "../pages/Profile/Profile";

import ViewAllSettings from "../pages/Settings/ViewAll";
import ViewPrefix from "../pages/Settings/ViewPrefix";
import AddSetting from "../pages/Settings/Add";
import EditSetting from "../pages/Settings/Edit";
import ViewAllCms from "../pages/Cms/ViewAll";
import ViewOneCms from "../pages/Cms/NewViewOne";
import AddCms from "../pages/Cms/NewAdd";
import EditCms from "../pages/Cms/NewEdit";

import ViewAllEmailTemplate from "../pages/EmailTemplate/ViewAll";
import AddEmailTemplate from "../pages/EmailTemplate/Add";
import EditEmailTemplate from "../pages/EmailTemplate/Edit";
import ViewAllEmailLogs from "../pages/EmailLog/ViewAll";

import ViewAllCandidate from "../pages/Candidate/ViewAll";
import AddCandidate from "../pages/Candidate/AddNew";
import EditCandidate from "../pages/Candidate/EditNew";
import ViewCandidate from "../pages/Candidate/ViewOne";
import ChangePasswordCandidate from "../pages/Candidate/ChangePassword";
import DownloadCvCandidate from "../pages/Candidate/DownloadCv";

import ViewAllBlock from "../pages/Block/ViewAll";
import AddBlock from "../pages/Block/Add";
import EditBlock from "../pages/Block/Edit";

import ViewAllTestimonial from "../pages/Testimonial/ViewAll";
import AddTestimonial from "../pages/Testimonial/Add";
import EditTestimonial from "../pages/Testimonial/Edit";

import ViewAllContact from "../pages/Contact/ViewAll";
import ViewOneContact from "../pages/Contact/ViewOne";

import ViewAllNewsletter from "../pages/Newsletter/ViewAll";

import ViewAllAdminRoles from "../pages/AdminRole/ViewAll";
import AddAdminRole from "../pages/AdminRole/Add";
import EditAdminRole from "../pages/AdminRole/Edit";

import ViewAllSubAdmin from "../pages/SubAdmin/ViewAll";
import AddSubadmin from "../pages/SubAdmin/Add";
import EditSubadmin from "../pages/SubAdmin/Edit";
import ViewOneSubAdmin from "../pages/SubAdmin/ViewOne";
import ChangePasswordSubadmin from "../pages/SubAdmin/ChangePassword";

import ViewAllJobIndustry from "../pages/JobIndustry/ViewAll";
import AddJobIndustry from "../pages/JobIndustry/Add";
import EditJobIndustry from "../pages/JobIndustry/Edit";

import ViewAllJobCategory from "../pages/JobCategory/ViewAll";
import AddJobCategory from "../pages/JobCategory/Add";
import EditJobCategory from "../pages/JobCategory/Edit";

import ViewAllJobType from "../pages/JobType/ViewAll";
import AddJobType from "../pages/JobType/Add";
import EditJobType from "../pages/JobType/Edit";

import ViewAllStudyTopic from "../pages/StudyTopic/ViewAll";
import AddStudyTopic from "../pages/StudyTopic/Add";
import EditStudyTopic from "../pages/StudyTopic/Edit";

import ViewAllStudyField from "../pages/StudyFields/ViewAll";
import AddStudyField from "../pages/StudyFields/Add";
import EditStudyField from "../pages/StudyFields/Edit";

import ViewAllGrade from "../pages/Grade/ViewAll";
import AddGrade from "../pages/Grade/Add";
import EditGrade from "../pages/Grade/Edit";

import ViewAllGraduationCertificate from "../pages/GraduationCertificate/ViewAll";
import AddGraduationCertificate from "../pages/GraduationCertificate/Add";
import EditGraduationCertificate from "../pages/GraduationCertificate/Edit";


import ViewAllDegreeLevel from  "../pages/DegreeLevel/ViewAll";
import AddDegreeLevel from "../pages/DegreeLevel/Add";
import EditDegreeLevel from "../pages/DegreeLevel/Edit";

import ViewAllSkills from "../pages/Skills/ViewAll";
import AddSkills  from "../pages/Skills/Add";
import EditSkills  from "../pages/Skills/Edit";

import ViewAllLanguages from "../pages/Languages/ViewAll";
import AddLanguages from "../pages/Languages/Add";
import EditLanguages from "../pages/Languages/Edit";

import ViewAllCareerLevel from  "../pages/CareerLevel/ViewAll";
import AddCareerLevel from "../pages/CareerLevel/Add";
import EditCareerLevel from "../pages/CareerLevel/Edit";

import ViewAllStatus from  "../pages/Status/ViewAll";
import AddStatus from "../pages/Status/Add";
import EditStatus from "../pages/Status/Edit";

import ViewAllLivesIn from  "../pages/LivesIn/ViewAll";
import AddLivesIn from "../pages/LivesIn/Add";
import EditLivesIn from "../pages/LivesIn/Edit";

import ViewAllPostDegreeLevel from  "../pages/PostDegreeLevel/ViewAll";
import AddPostDegreeLevel from "../pages/PostDegreeLevel/Add";
import EditPostDegreeLevel from "../pages/PostDegreeLevel/Edit";

export const privateRoutes = [
  { path: "/", component: Dashboard, key: "0" },
  { path: "/change-password", component: ChangePassword, key: "0" },
  { path: "/my-profile", component: Profile, key: "0" },

  { path: "/setting", component: ViewAllSettings, key: "80_1" },
  { path: "/setting/prefix/Site", component: ViewPrefix, key: "80_4" },
  { path: "/setting/prefix/Reading", component: ViewPrefix, key: "80_5" },
  { path: "/setting/prefix/Social", component: ViewPrefix, key: "80_6" },
  { path: "/setting/prefix/Contact", component: ViewPrefix, key: "80_7" },
  { path: "/setting/add", component: AddSetting, key: "80_2" },
  { path: "/setting/edit/:id", component: EditSetting, key: "80_3" },

  { path: "/cms", component: ViewAllCms, key: "40_1" },
  { path: "/cms/view/:id", component: ViewOneCms, key: "40_2" },
  { path: "/cms/add", component: AddCms, key: "40_3" },
  { path: "/cms/edit/:id", component: EditCms, key: "40_4" },

  { path: "/email-template", component: ViewAllEmailTemplate, key: "50_1" },
  { path: "/email-template/add", component: AddEmailTemplate, key: "50_2" },
  {
    path: "/email-template/edit/:id",
    component: EditEmailTemplate,
    key: "50_3",
  },

  { path: "/email-logs", component: ViewAllEmailLogs, key: "60_1" },

  { path: "/candidate", component: ViewAllCandidate, key: "10_1" },
  { path: "/candidate/add", component: AddCandidate, key: "10_3" },
  { path: "/candidate/edit/:id", component: EditCandidate, key: "10_4" },
  { path: "/candidate/view/:id", component: ViewCandidate, key: "10_2" },
  { path: "/candidate/download-cv/:id", component: DownloadCvCandidate, key : "10_9" },
  {
    path: "/candidate/change-password/:id",
    component: ChangePasswordCandidate,
    key: "10_6",
  },

  { path: "/block", component: ViewAllBlock, key: "90_1" },
  { path: "/block/add", component: AddBlock, key: "90_3" },
  { path: "/block/edit/:id", component: EditBlock, key: "90_4" },

  { path: "/testimonial", component: ViewAllTestimonial, key: "100_1" },
  { path: "/testimonial/add", component: AddTestimonial, key: "100_3" },
  { path: "/testimonial/edit/:id", component: EditTestimonial, key: "100_4" },

  { path: "/contact", component: ViewAllContact, key: "71_1" },
  { path: "/contact/view/:id", component: ViewOneContact, key: "71_2" },

  { path: "/newsletter", component: ViewAllNewsletter, key: "72_1" },

  { path: "/admin-roles", component: ViewAllAdminRoles, key: "30_1" },
  { path: "/admin-roles/add", component: AddAdminRole, key: "30_2" },
  { path: "/admin-roles/edit/:id", component: EditAdminRole, key: "30_3" },

  { path: "/sub-admin", component: ViewAllSubAdmin, key: "20_1" },
  { path: "/sub-admin/add", component: AddSubadmin, key: "20_3" },
  { path: "/sub-admin/edit/:id", component: EditSubadmin, key: "20_4" },
  { path: "/sub-admin/view/:id", component: ViewOneSubAdmin, key: "20_2" },
  {
    path: "/sub-admin/change-password/:id",
    component: ChangePasswordSubadmin,
    key: "20_6",
  },

  { path: "/job-industry", component: ViewAllJobIndustry, key: "110_1" },
  { path: "/job-industry/add", component: AddJobIndustry, key: "110_3" },
  { path: "/job-industry/edit/:id", component: EditJobIndustry, key: "110_4" },

  { path: "/job-category", component: ViewAllJobCategory, key: "120_1" },
  { path: "/job-category/add", component: AddJobCategory, key: "120_3" },
  { path: "/job-category/edit/:id", component: EditJobCategory, key: "120_4" },

  { path: "/job-type", component: ViewAllJobType, key: "130_1" },
  { path: "/job-type/add", component: AddJobType, key: "130_3" },
  { path: "/job-type/edit/:id", component: EditJobType, key: "130_4" },

  { path: "/study-topic", component: ViewAllStudyTopic, key: "140_1" },
  { path: "/study-topic/add", component: AddStudyTopic, key: "140_3" },
  { path: "/study-topic/edit/:id", component: EditStudyTopic, key: "140_4" },

  { path: "/study-field", component: ViewAllStudyField, key: "150_1" },
  { path: "/study-field/add", component: AddStudyField, key: "150_3" },
  { path: "/study-field/edit/:id", component: EditStudyField, key: "150_4" },

  { path: "/grade", component: ViewAllGrade, key: "160_1" },
  { path: "/grade/add", component: AddGrade, key: "160_3" },
  { path: "/grade/edit/:id", component: EditGrade, key: "160_4" },

  {
    path: "/graduation-certificate",
    component: ViewAllGraduationCertificate,
    key: "170_1",
  },
  {
    path: "/graduation-certificate/add",
    component: AddGraduationCertificate,
    key: "170_3",
  },
  {
    path: "/graduation-certificate/edit/:id",
    component: EditGraduationCertificate,
    key: "170_4",
  },

  { path: "/degree-level", component: ViewAllDegreeLevel, key: "180_1" },
  { path: "/degree-level/add", component: AddDegreeLevel, key: "180_2" },
  { path: "/degree-level/edit/:id", component: EditDegreeLevel, key: "180_3" },

  { path: "/skills", component: ViewAllSkills, key: "190_1" },
  { path: "/skills/add", component: AddSkills, key: "190_2" },
  { path: "/skills/edit/:id", component: EditSkills, key: "190_3" },

  { path: "/languages", component: ViewAllLanguages, key: "200_1" },
  { path: "/languages/add", component: AddLanguages, key: "200_2" },
  { path: "/languages/edit/:id", component: EditLanguages, key: "200_3" },

  { path: "/career-level", component: ViewAllCareerLevel, key: "210_1" },
  { path: "/career-level/add", component: AddCareerLevel, key: "210_2" },
  { path: "/career-level/edit/:id", component: EditCareerLevel, key: "210_3" },

  { path: "/status", component: ViewAllStatus, key: "220_1" },
  { path: "/status/add", component: AddStatus, key: "220_2" },
  { path: "/status/edit/:id", component: EditStatus, key: "220_3" },


  { path: "/lives-in", component: ViewAllLivesIn, key: "230_1" },
  { path: "/lives-in/add", component: AddLivesIn, key: "230_2" },
  { path: "/lives-in/edit/:id", component: EditLivesIn, key: "230_3" },

  { path: "/post-degree-level", component: ViewAllPostDegreeLevel, key: "240_1" },
  { path: "/post-degree-level/add", component: AddPostDegreeLevel, key: "240_2" },
  { path: "/post-degree-level/edit/:id", component: EditPostDegreeLevel, key: "240_3" },
];

export const notPrivateRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/set-password/:token", component: SetPassword },
  { path: "/reset-password/:token", component: ResetPassword },
];

// export const noLayoutPrivateRoutes = [
//   { path: "/candidate/download-cv/:id", component: DownloadCvCandidate, key : "10_9" },
// ]
