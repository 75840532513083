import React, { Fragment } from "react";
import {
  Text,
  Font,
  Page,
  View,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";
import { getLatestCompany, timeGapYearMonth } from "../../util/fn";
import { API } from "../../constant/api";

const Title = ({ children }) => <Text style={styles.title}>{children}</Text>;

const styles = StyleSheet.create({
  page: {
    padding: "15px",
    // flexDirection: "row",
  },
  tittle_heading: {
    fontSize: 16,
    color: "#000",
    fontWeight: "900",
    display: "block",
    marginTop: 20,
    marginBottom: 5,
  },

  tittle_heading_profile: {
    fontSize: 16,
    color: "#000",
    fontWeight: "900",
    display: "block",
  },
  dis_text: {
    fontSize: 11,
    color: "rgb(108 108 108)",
    fontWeight: "400",
  },

  inner_wrapper: {
    display: "flex",
    flexDirection: "row",
  },
  left_block: {
    minWidth: 400,
    flex: "0 0 400",
  },
  right_block: {
    minWidth: 170,
    flex: "0 0 170",
    paddingLeft: 20,
    marginLeft: "auto",
  },
  img_style: {
    width: 16,
    height: 16,
    paddingRight: 10,
  },
  sub_heading: {
    fontSize: 13,
    color: "#000",
    fontWeight: "700",
    display: "block",
    paddingBottom: 5,
  },
  sub_heading_his: {
    fontSize: 13,
    color: "#000",
    fontWeight: "700",
    display: "block",
    paddingBottom: 5,
    paddingLeft: 20,
  },
  sub_heading_edu: {
    fontSize: 13,
    color: "#000",
    fontWeight: "700",
    display: "block",
    paddingLeft: 20,
    marginTop: 5,
    paddingBottom: 5,
  },
  sub_heading_skills: {
    fontSize: 13,
    color: "#000",
    fontWeight: "700",
    display: "block",
    paddingBottom: 10,
    paddingTop: 10,
  },
  left_space: {
    paddingLeft: 20,
  },
  item_block: {
    flexDirection: "row",
    gap: 7,
    paddingLeft: 20,
    marginTop: 5,
  },
  sub_text: {
    fontSize: 10,
  },
  sub_text_education: {
    fontSize: 10,
    paddingLeft: 20,
  },
  text_size: {
    display: "block",
    paddingLeft: 20,
    fontSize: 10,
    marginTop: 5,
  },
  date_text: {
    fontSize: 10,
    color: "#adadad",
    fontWeight: "400",
    display: "block",
    paddingLeft: 20,
    marginTop: 3,
  },
  top_space: {
    paddingTop: 15,
  },
  progress_container: {
    width: "100%",
    backgroundColor: "#e9ecef",
    display: "block",
  },

  // blue_back: {
  //   width: "60%",
  //   display: "block",
  //   backgroundColor: "#56cdad",
  //   color: "blue",
  //   height: 5,
  // },
  header_block: {
    flexDirection: "row",
    gap: 10,
    alignItems: "center",
  },
  profile_img: {
    width: 80,
    height: 80,
    borderRadius: 100,
  },
});

const skillLevel = {
  basic: "25%",
  average: "50%",
  good: "75%",
  expert: "100%",
};

const CvTemplate = ({ profileData }) => {
  let latestCompany = getLatestCompany(profileData?.workExperience);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header_block}>
          <View>
            <Image
              src={`${API.PORT}/${profileData?.general?.profile_pic}`}
              style={styles.profile_img}
            />
          </View>
          <View>
            <Text style={styles.tittle_heading_profile}>
              {profileData?.general?.first_name}{" "}
              {profileData?.general?.last_name}
            </Text>
            <Text style={styles.dis_text}>
              {latestCompany?.job_title} at {latestCompany?.company_name || ""}
            </Text>
            {/* <Text style={styles.dis_text}>
              {generalData?.current_city_name},
              {generalData?.current_country_name}{" "}
            </Text> */}
          </View>
        </View>
        <View style={styles.inner_wrapper}>
          <View style={styles.left_block}>
            <Text style={styles.tittle_heading}>
              <Image src="./pdfuser.png" style={styles.img_style} />
              &nbsp;Profile
            </Text>
            <Text style={{ ...styles.dis_text, ...styles.left_space }}>
              {profileData?.general?.about}
            </Text>

            <Text style={styles.tittle_heading}>
              <Image src="./pdficon.png" style={styles.img_style} />
              &nbsp;Employment History
            </Text>

            {profileData?.workExperience?.map((val) => {
              return (
                <Fragment key={val.id}>
                  <Text style={styles.sub_heading_his}>
                    {val?.company_name}
                  </Text>
                  <View style={styles.item_block}>
                    <Text style={styles.sub_text}>{val?.job_title} </Text>
                    <Text style={styles.sub_text}>. {val?.job_type_name}</Text>
                    <Text style={styles.sub_text}>
                      . {moment(val?.start_date).format("MMM-YYYY")} -
                      {val?.end_date
                        ? moment(val?.end_date).format("MMM-YYYY")
                        : "Present"}{" "}
                      (
                      {timeGapYearMonth(
                        val.start_date,
                        val?.end_date || moment()
                      )}
                      )
                    </Text>
                  </View>
                  <Text style={styles.text_size}>
                    . Achievement : {val?.achievements}
                  </Text>
                  <Text style={styles.text_size}>
                    . Responsibilites : {val?.responsibilities}
                  </Text>
                </Fragment>
              );
            })}

            <Text style={styles.tittle_heading}>
              <Image src="./pdficon.png" style={styles.img_style} />
              &nbsp;Education
            </Text>

            {profileData?.postGraduation?.map((val) => {
              return (
                <Fragment key={val.id}>
                  <Text style={styles.sub_heading_edu}>
                    {val.university_name}
                  </Text>
                  <Text style={styles.sub_text_education}>
                    {/* {val.field_of_study_name
                      ? val.field_of_study_name
                      : val.other_field_of_study}
                    ,{" "}
                    {val.degree_level_name
                      ? val.degree_level_name
                      : val.other_degree_level} */}
                    Grade ({val.grade_name})
                  </Text>
                  <Text style={styles.date_text}>
                    {val.start_year}-{val.end_year}
                  </Text>
                </Fragment>
              );
            })}

            {profileData?.university?.map((val) => {
              return (
                <Fragment key={val.id}>
                  <Text style={styles.sub_heading_edu}>
                    {val.university_name}
                  </Text>
                  <Text style={styles.sub_text_education}>
                    {/* {val.field_of_study_name
                      ? val.field_of_study_name
                      : val.other_field_of_study}
                    ,{" "}
                    {val.degree_level_name
                      ? val.degree_level_name
                      : val.other_degree_level} */}
                    Grade ({val.grade_name})
                  </Text>
                  <Text style={styles.date_text}>
                    {val.start_year}-{val.end_year}
                  </Text>
                </Fragment>
              );
            })}

            <Text style={styles.sub_heading_edu}>
              {profileData?.education?.high_school_name}
            </Text>
            <Text style={styles.sub_text_education}>
              {profileData?.education?.graduation_certificate_name}
            </Text>
            <Text style={styles.date_text}>
              {profileData?.education?.graduation_year}
            </Text>

            <Text style={styles.tittle_heading}>
              <Image src="./pdficon.png" style={styles.img_style} />
              &nbsp;Certification
            </Text>

            {profileData?.certification?.map((val) => {
              return (
                <Fragment key={val.id}>
                  <Text style={styles.sub_heading_edu}>
                    {val?.organisation_name}
                  </Text>
                  <Text style={styles.sub_text_education}>
                    {val?.field_of_study_name
                      ? val?.field_of_study_name
                      : val?.other_field_of_study}
                  </Text>
                  <Text style={styles.date_text}>{val?.graduation_year}</Text>
                </Fragment>
              );
            })}
          </View>

          <View style={styles.right_block}>
            <Text style={styles.tittle_heading}>Details</Text>
            <Text style={styles.dis_text}>
              {profileData?.general?.current_area},{" "}
              {profileData?.general?.current_city_name},{" "}
              {profileData?.general?.current_country_name}
            </Text>
            <Text style={styles.tittle_heading}>Additional Details</Text>
            <Text style={styles.dis_text}>
              {profileData?.general?.contact_email}
            </Text>
            <Text style={styles.dis_text}> 
              {profileData?.general?.contact_mobile}
            </Text>

            <Text style={{ ...styles.top_space, ...styles.tittle_heading }}>
              Skills
            </Text>
            {profileData?.skills?.map((val) => {
              return (
                <Fragment key={val.id}>
                  <Text style={styles.sub_heading_skills}>
                    {val.skill_name}
                  </Text>
                  <View style={styles.progress_container}>
                    <View
                      style={{
                        width: [`${skillLevel[val.level]}`],
                        display: "block",
                        backgroundColor: "#56cdad",
                        color: "blue",
                        height: 5,
                      }}
                    ></View>
                  </View>
                </Fragment>
              );
            })}
            <Text style={{ ...styles.top_space, ...styles.tittle_heading }}>
              Language
            </Text>

            {profileData?.languages.map((val) => {
              return (
                <Fragment key={val.id}>
                  <Text style={styles.sub_heading_skills}>
                    {val.language_name}
                  </Text>
                  <View style={styles.progress_container}>
                    <View
                      style={{
                        width: [`${skillLevel[val.level]}`],
                        display: "block",
                        backgroundColor: "#56cdad",
                        color: "blue",
                        height: 5,
                      }}
                    ></View>
                  </View>
                </Fragment>
              );
            })}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CvTemplate;
