import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { API } from "../../constant/api";
import { SubTab } from "../Cms/TabNInput";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

const Activated = () => (
  <span className="label label-lg label-light-success label-inline">
    Activated
  </span>
);

const Deactivated = () => (
  <span className="label label-lg label-light-danger label-inline">
    Deactivated
  </span>
);

const ViewOne = (props) => {
  const { id: seekerId } = props.match.params;

  const [tabIndex, setTabIndex] = useState(0);
  const [userData, setUserData] = useState([]);

  const { response: responseUserData, request: requestUserData } = useRequest();

  const { date_format } = useSelector((state) => state.setting);

  useEffect(() => {
    requestUserData("GET", `user/${seekerId}`);

    document.title = "View Candidate- Native";
  }, []);

  useEffect(() => {
    if (responseUserData) {
      const { first_name, last_name, email, isActive, created_at } =
        responseUserData.user;
      const { profile_picture, gender, status, lives_in, yob } =
        responseUserData.details;

      setUserData([
        {
          title: "Profile Picture",
          value:
            profile_picture?.length > 0 ? (
              <div className="d-flex" style={{ gap: "20px" }}>
                <img
                  data-fancybox
                  src={`${API.PORT}/${profile_picture}`}
                  width="100px"
                  alt=""
                />
              </div>
            ) : (
              <p>No pictures found !</p>
            ),
        },
        { title: "First Name", value: first_name },
        { title: "Last Name", value: last_name },
        { title: "Email", value: email },
        { title: "Gender", value: gender },
        { title: "Status", value: status },
        { title: "Lives In", value: lives_in },
        { title: "Birth year", value: yob },
        {
          title: "Registered On",
          value: <Moment format={date_format}>{created_at}</Moment>,
        },
        {
          title: "Status",
          value: isActive === true ? <Activated /> : <Deactivated />,
        },
      ]);
    }
  }, [responseUserData]);

  const currentTabIndexHandler = (idx) => {
    setTabIndex(idx);
  };

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="View Candidate"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/candidate", name: "Back To Candidates" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card card-custom gutter-b">
            <div className="card-header card-header-tabs-line">
              <div className="card-toolbar">
                <ul
                  className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                  role="tablist"
                >
                  {["Candidate Information"].map((data, index) => (
                    <SubTab
                      key={index}
                      name={data}
                      index={index}
                      onClick={currentTabIndexHandler}
                    />
                  ))}
                </ul>
              </div>
            </div>

            <div
              style={{
                display: tabIndex === 0 ? "block" : "none",
              }}
              className="card-body px-0"
            >
              {/* <div className="card-body px-0"> */}
              <div className="tab-content px-10">
                <div
                  className={`tab-pane ${tabIndex === 0 ? "active" : ""}`}
                  id={`kt_apps_contacts_view_tab_0`}
                  role="tabpanel"
                >
                  {userData.length > 0 &&
                    userData.map((user, index) => (
                      <div key={index} className="form-group row my-2">
                        <label className="col-4 col-form-label">
                          {user.title}
                        </label>
                        <div className="col-8">
                          <span className="form-control-plaintext font-weight-bolder">
                            {user.value}
                          </span>
                        </div>
                      </div>
                    ))}

                  <div className="row"></div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOne;
