import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Moment from "moment";
import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Pagination from "../../components/Pagination/Pagination";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Table from "../../components/Table/Table";
import {
  SearchInput,
  SearchSubmitButton,
  SelectInput,
} from "../../components/Form/Form";
import { addOneToDate, isHaveAccess } from "../../util/fn";
import { API } from "../../constant/api";

const OBJ_TABLE = {
  // "profile picture": "profile_picture",
  "first name": "first_name",
  "last name": "last_name",
  email: "email",
  // "phone number": "phone_number",
  // region: "regionName",
  // district: "districtName",
  "registered on": "created_at",
  status: "isActive",
};

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  isActive = "",
  firstName = "",
  lastName = "",
  email = "",
  dateFrom = "1970-01-01",
  dateTo
) => {
  if (sortBy.length > 0) {
    if (sortBy == "registered on") {
      sortBy = "created_at";
    } else if (sortBy === "phone number") {
      sortBy = "contact";
    }
  } else {
    sortBy = "created_at";
  }
  order = order.length > 0 ? order : "desc";

  dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";

  // dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());
  dateTo = dateTo ? addOneToDate(new Date(dateTo)) : "";

  return `user/all?page=${page}&per_page=${per_page}&sort_by=${sortBy}&order=${order}&is_active=${isActive}&first_name=${firstName}&last_name=${lastName}&email=${email}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
};

const ViewAll = ({ location }) => {
  const [seekers, setSeekers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "registered on",
    order: "desc",
  });

  const MySwal = withReactContent(Swal);

  const { records_per_page } = useSelector((state) => state.setting);
  const { permissions, user_role_id } = useSelector((store) => store.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    clearErrors,
    setError,
    setValue,
  } = useForm();

  const { request, response } = useRequest();
  const { request: requestChangeStatus, response: responseChangeStatus } =
    useRequest();
  const { request: requestVerify, response: responseVerify } = useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();
  const { request: requestSendCreds, response: responseSendCreds } =
    useRequest();
  const { request: loginRequest, response: loginResponce } = useRequest();

  useEffect(() => {
    if (responseVerify) {
      console.log(("responseVerify", responseVerify));
      const { id, newStatus } = responseVerify;

      const oldSeekers = [...seekers];
      const indexToChange = oldSeekers.findIndex((seeker) => seeker.id == id);
      oldSeekers[indexToChange].admin_verifying = newStatus ? true : false;
      oldSeekers[indexToChange].admin_verifying = +newStatus;

      setSeekers(oldSeekers);
    }
  }, [responseVerify]);

  useEffect(() => {
    if (records_per_page) {
      // console.log("isss active", location);
      let isActive = location?.state?.isActive ?? "";
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order,
          isActive
        )
      );
    }
    document.title = "Candidates- Native";
  }, [records_per_page]);
  useEffect(() => {
    if (response) {
      setSeekers(response.users);
      setTotalDocuments((prev) =>
        response.totalDocuments ? response.totalDocuments : prev
      );
    }
  }, [response]);

  useEffect(() => {
    if (responseChangeStatus) {
      const { id, newStatus } = responseChangeStatus;

      const oldSeekers = [...seekers];
      const indexToChange = oldSeekers.findIndex((seeker) => seeker.id == id);
      oldSeekers[indexToChange].isActive = newStatus ? true : false;
      oldSeekers[indexToChange].is_active = +newStatus;

      setSeekers(oldSeekers);
    }
  }, [responseChangeStatus]);

  useEffect(() => {
    if (responseDelete) {
      const { id } = responseDelete;
      let newSeeker = [...seekers];
      newSeeker = newSeeker.filter((seeker) => seeker.id != id);
      setSeekers(newSeeker);
      toast.success("Job Seeker has been deleted successfully.");
    }
  }, [responseDelete]);

  useEffect(() => {
    if (responseSendCreds) {
      toast.success("Login credentials sent successfully");
    }
  }, [responseSendCreds]);

  useEffect(() => {
    if (loginResponce) {
      window.open(
        `http://192.168.235.200:3002?token=${loginResponce?.token}`,
        "_blank",
        "noopener,noreferrer"
      );
    }
  }, [loginResponce]);

  const fetchMoreData = ({ selected }) => {
    setSeekers([]);
    const { isActive, firstName, lastName, email, dateFrom, dateTo } =
      getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        firstName,
        lastName,
        email,
        dateFrom,
        dateTo
      )
    );
  };

  const deleteHandler = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You want to delete this candidate?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestDelete("DELETE", "user", { id });
      } else if (result.isDismissed) {
      }
    });
  };

  const downloadCvHandler = () => {
    alert("hiiii");
  };

  const onSearchHandler = (data) => {
    const { isActive, firstName, lastName, email, dateFrom, dateTo } =
      getValues();

    if (dateFrom && dateTo) {
      if (Moment(dateFrom).isAfter(dateTo)) {
        setError("dateTo", {
          type: "manual",
        });
        return;
      }
    }

    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        firstName,
        lastName,
        email,
        dateFrom,
        dateTo
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("isActive");
    resetField("firstName");
    resetField("lastName");
    resetField("email");
    resetField("dateFrom");
    resetField("dateTo");

    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const { isActive, firstName, lastName, email, dateFrom, dateTo } =
      getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        firstName,
        lastName,
        email,
        dateFrom,
        dateTo
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    const { isActive, firstName, lastName, email, dateFrom, dateTo } =
      getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          newOrder,
          isActive,
          firstName,
          lastName,
          email,
          dateFrom,
          dateTo
        )
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          "desc",
          isActive,
          firstName,
          lastName,
          email,
          dateFrom,
          dateTo
        )
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  const changeStatusHandler = (id, user) => {
    const status = user.is_active == 1 ? 0 : 1;

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestChangeStatus("PUT", "user/status", {
          id,
          status,
        });
      } else if (result.isDismissed) {
      }
    });
  };

  const sendCredentials = (id) => {
    requestSendCreds("POST", "user/send-credentials", { id });
  };

  const loginUser = (id) => {
    loginRequest("POST", "admin/login-user", { id });
  };

  const statusSelectedHandler = (data) => {
    setValue("isActive", data);
  };

  // const changeVerifyHandler = (id, user) => {
  //   console.log("user", user);
  //   const status = user.admin_verifying == 1 ? 0 : 1;

  //   MySwal.fire({
  //     title: "Are you sure?",
  //     text: "Want to verify?",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes, change it",
  //     cancelButtonText: "No, cancel",
  //     reverseButtons: true,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       requestVerify("POST", "user/verify-admin", {
  //         id,
  //         status,
  //       });
  //     } else if (result.isDismissed) {
  //     }
  //   });
  // };

  const InputFields = [
    {
      label: "First Name",
      name: "firstName",
      required: false,
    },
    {
      label: "Last Name",
      name: "lastName",
      required: false,
    },
    {
      label: "Email",
      name: "email",
      required: false,
    },

    {
      isSelectInput: true,
      label: "Status",
      name: "isActive",
      required: false,
      onChange: statusSelectedHandler,
      children: (
        <>
          <option value="">Select</option>
          <option value={"true"}>Active</option>
          <option value={"false"}>Deactive</option>
        </>
      ),
    },
    {
      label: "Date From",
      name: "dateFrom",
      isDate: true,
      clearErrors,
    },
    {
      label: "Date To",
      name: "dateTo",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback: "'To Date' cannot be smaller than 'From Date'",
      },
    },
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb title="Candidates" links={[{ to: "/", name: "Dashboard" }]} />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>

                    {user_role_id != 1 ? (
                      <>
                        {isHaveAccess("30_2", permissions) && (
                          <Link to="/candidate/add" className="btn btn-primary">
                            Add New Candidate
                          </Link>
                        )}
                      </>
                    ) : (
                      <>
                        <Link to="/candidate/add" className="btn btn-primary">
                          Add New Candidate
                        </Link>
                      </>
                    )}
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={seekers}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      renderAs={{
                        is_active: (val) => (val ? "True" : false),
                        created_at: (val) => moment(val).format("DD-MM-YYYY"),
                        profile_picture: (val) => {
                          if (val) {
                            return (
                              <div className="d-flex" style={{ gap: "20px" }}>
                                <img
                                  data-fancybox
                                  src={`${API.PORT}/${val}`}
                                  width="50px"
                                  alt=""
                                />
                              </div>
                            );
                          } else {
                            // return "Logo Not Available";
                            return "";
                          }
                        },
                        are_you_a_recruitment_agency: (val) =>
                          val
                            ? val.charAt(0).toUpperCase() + val.slice(1)
                            : "Not Available",
                      }}
                      links={[
                        // {
                        //   isLink: true,
                        //   to: `/wallet/view`,
                        //   name: "Wallet",
                        //   extraData: true,
                        // },
                        {
                          isLink: false,
                          name: "Deactivate",
                          click: changeStatusHandler,
                          title: "Click To Activate",
                          key: ["10_8"],
                        },
                        {
                          isLink: false,
                          name: "Activate",
                          click: changeStatusHandler,
                          title: "Click To Deactivate",
                          key: ["10_8"],
                        },
                        {
                          isLink: true,
                          to: "/candidate/view",
                          name: "View",
                          extraData: true,
                          key: ["10_2"],
                        },
                        {
                          isLink: true,
                          to: "/candidate/edit",
                          name: "Edit",
                          extraData: true,
                          key: ["10_4"],
                        },
                        {
                          isLink: false,
                          name: "Delete",
                          click: deleteHandler,
                          key: ["10_5"],
                        },
                        {
                          isLink: true,
                          to: "/candidate/change-password",
                          name: "ChangePassword",
                          extraData: true,
                          title: "Change Password",
                          key: ["10_6"],
                        },
                        // {
                        //   isLink: false,
                        //   name: "Unverify",
                        //   click: changeVerifyHandler,
                        //   title: "Click To Unverify",
                        //   key: ["10_11"],
                        // },
                        // {
                        //   isLink: false,
                        //   name: "Verify",
                        //   click: changeVerifyHandler,
                        //   title: "Click To Verify",
                        //   key: ["10_10"],
                        // },
                        {
                          isLink: false,
                          name: "SendCreds",
                          click: sendCredentials,
                          title: "Send Credentials",
                          key: ["10_7"],
                        },
                        {
                          isLink: true,
                          to: "/candidate/download-cv",
                          name: "DownloadCv",
                          extraData: true,
                          title: "Download CV",
                          key: ["10_9"],
                        },
                        // {
                        //   isLink: false,
                        //   name: "Login",
                        //   click: loginUser,
                        //   title: "Login",
                        // },
                      ]}
                      onlyDate={{
                        createdAt: "date",
                        startDate: "dateTime",
                        endDate: "dateTime",
                      }}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={seekers.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
