import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import useRequest from "../../hooks/useRequest";
import Pagination from "../../components/Pagination/Pagination";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Table from "../../components/Table/Table";
import { SearchInput, SearchSubmitButton } from "../../components/Form/Form";
import { addOneToDate, intersect, isHaveAccess } from "../../util/fn";
import moment from "moment";

const OBJ_TABLE = {
  Role: "role",
  "registered on": "createdAt",
  status: "isActive",
};

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  isActive="",
  role=""
) => {
  if (sortBy.length > 0) {
    if (sortBy == "registered on") {
      sortBy = "createdAt";
    }
  } else {
    sortBy = "createdAt";
  }
  order = order.length > 0 ? order : "desc";

  return `admin-role/all?page=${page}&per_page=${per_page}&sortBy=${sortBy}&order=${order}&is_active=${isActive}&role=${role}`;
};

const ViewAllTypes = () => {
  const [adminRoles, setAdminRoles] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "registered on",
    order: "desc",
  });
  

  const MySwal = withReactContent(Swal);

  const { records_per_page } = useSelector((state) => state.setting);
  const { permissions, user_role_id } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    clearErrors,
    setValue,
    setError,
  } = useForm();
 
  const { request, response } = useRequest();
  const { request: requestChangeStatus, response: responseChangeStatus } =
    useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order,
        )
      );
    }
    document.title = "Admin Role - Native";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      setAdminRoles(response.roles);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  useEffect(() => {
    if (responseChangeStatus) {
      const { id, newStatus } = responseChangeStatus;

      const updatedData = [...adminRoles];
      const indexToChange = updatedData.findIndex(
        (scholar) => scholar.id == id
      );
      updatedData[indexToChange].isActive = newStatus ? true : false;
      updatedData[indexToChange].is_active = +newStatus;

      setAdminRoles(updatedData);
    }
  }, [responseChangeStatus]);

  useEffect(() => {
    if (responseDelete && responseDelete.status) {
      const { id } = responseDelete;
      let updatedData = [...adminRoles];
      updatedData = updatedData.filter((ty) => ty.id != id);
      toast.success("Admin role has been deleted successfully.");
      setAdminRoles(updatedData);
    }
  }, [responseDelete]);


  const fetchMoreData = ({ selected }) => {
    setAdminRoles([]);
    const { isActive, role } = getValues();
    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        role
      )
    );
  };

  const deleteHandler = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You want to delete this role?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestDelete("DELETE", "admin-role", {id} );
      } else if (result.isDismissed) {
      }
    });
  };

  const onSearchHandler = (data) => {
    let { isActive, role } = data;

    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        role
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("isActive");
    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const { isActive, role } = getValues();
    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        role
      )
    );
    setPage(1);
  };

  const sortingHandler = (sortBy) => {
    const {
      isActive,
      email,
      dateFrom,
      dateTo,      
    } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          newOrder,
          isActive,
          email,          
          dateFrom,
          dateTo
        )
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          "desc",
          isActive,
          email,
          dateFrom,
          dateTo
        )
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  };


  const changeStatusHandler = (id, role) => {
    const status = role.is_active == 1 ? 0 : 1;

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestChangeStatus("PUT", "admin-role/status", {
          id,
          status,
        });
      } else if (result.isDismissed) {
      }
    });
  };



  const InputFields = [
    {
      label: "Role",
      name: "role",
      required: false,
    },
    {
      label: "Status",
      name: "isActive",
      required: false,
      isSelectInput: true,
      //onChange: statusSelectedHandler,
      children: (
        <>
          <option value="">Select</option>
          <option value={true}>Active</option>
          <option value={false}>Not active</option>
        </>
      ),
    },

    {
      label: "Date From",
      name: "dateFrom",
      isDate: true,
      clearErrors,
    },
    {
      label: "Date To",
      name: "dateTo",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback: "'To Date' cannot be smaller than 'From Date'",
      },
    },
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Admin Roles"
        links={[{ to: "/", name: "Dashboard" }]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>

                    {/* {(roleId === 1 ||
                      (roleId === 2 &&
                        permissions &&
                        intersect(permissions, ["y4"]))) && (
                    <Link to="/admin-roles/add" className="btn btn-primary">
                      Add New Role
                    </Link>
                   )}  */}
                   {/* <Link to="/admin-roles/add" className="btn btn-primary">
                      Add New Role
                    </Link> */}
                    {user_role_id != 1 ? (
                      <>
                        {isHaveAccess("30_2", permissions) && (
                          <Link to="/admin-roles/add" className="btn btn-primary">
                             Add New Role
                          </Link>
                        )}
                      </>
                    ) : (
                      <>
                        <Link to="/admin-roles/add" className="btn btn-primary">
                        Add New Role
                        </Link>
                      </>
                    )}
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={adminRoles}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      renderAs={{
                        is_active: (val) => (val ? "True" : "false"),
                      }}
                      links={[
                        
                        {
                          isLink: false,
                          name: "Deactivate",
                          click: changeStatusHandler,
                          title: "Click To Activate",
                          key: ["30_4"]
                        },
                        {
                          isLink: false,
                          name: "Activate",
                          click: changeStatusHandler,
                          title: "Click To Deactivate",
                          key: ["30_4"]
                        },
                        {
                          isLink: true,
                          to: "/admin-roles/edit",
                          name: "Edit",
                          extraData: true,
                          key: ["30_3"]
                        },
                        {
                          isLink: false,
                          name: "Delete",
                          click: deleteHandler,
                          key: ["30_5"]
                        },
                      ]}
                      onlyDate={{
                        createdAt: "date",
                        startDate: "dateTime",
                        endDate: "dateTime",
                        deadline: "dateTime",
                      }}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={adminRoles.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAllTypes;
