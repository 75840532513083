import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import {
  Input,
  RenderInputFields,
  SubmitButton,
  SelectInput,
} from "../../components/Form/Form";
import { API } from "../../constant/api";

const Edit = (props) => {
  const { id: candidateId } = props.match.params;
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [status, setStatus] = useState();
  const [livesIn, setLivesIn] = useState();
  const [oldPicture, setOldPicture] = useState("");

  const { response, request } = useRequest();
  const { request: requestStatus, response: responseStatus } = useRequest();
  const { request: requestLivesIn, response: responseLivesIn } = useRequest();
  const { response: responseFetchUser, request: requestFetchSeeker } =
    useRequest();

  useEffect(() => {
    requestStatus("GET", "master/user-status");
    requestLivesIn("GET", "master/countries");
  }, []);

  useEffect(() => {
    if (responseStatus && responseStatus.status) {
      setStatus(responseStatus?.list);
    }
  }, [responseStatus]);

  useEffect(() => {
    if (responseLivesIn && responseLivesIn.status) {
      setLivesIn(responseLivesIn?.list?.record);
    }
  }, [responseLivesIn]);

  useEffect(() => {
    if (candidateId) {
      requestFetchSeeker("GET", `user/edit/${candidateId}`);
      document.title = "Edit Candidate - Native";
    }
  }, [candidateId]);

  useEffect(() => {
    if (responseFetchUser) {
      const { first_name, last_name, email } = responseFetchUser.user;
      const { profile_picture, gender, status, lives_in, yob } =
        responseFetchUser.details;

      setValue("first_name", first_name);
      setValue("last_name", last_name);
      setValue("email", email);
      setValue("gender", gender);
      setValue("status", status);
      setValue("lives_in", lives_in);
      setValue("yob", yob);
      if (profile_picture) {
        setOldPicture(profile_picture);
      }
    }
  }, [responseFetchUser]);

  useEffect(() => {
    if (response) {
      toast.success("Job Seeker has been updated successfully.");
      history.push("/candidate");
    }
  }, [response]);

  const onSubmit = (data) => {
    const {
      profile_picture,
      first_name,
      last_name,
      email,
      gender,
      status,
      lives_in,
      yob,
    } = data;

    let formData = new FormData();
    formData.append("id", candidateId);
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("gender", gender);
    formData.append("status", status);
    formData.append("lives_in", lives_in);
    formData.append("yob", yob);
    if (profile_picture && profile_picture[0]) {
      formData.append("profile_picture", profile_picture[0]);
    }
    request("PUT", "user", formData);
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "Profile Picture",
        type: "file",
        name: "profile_picture",
        registerFields: {
          //   required: true,
        },
        children: oldPicture ? (
          <img data-fancybox src={`${API.PORT}/${oldPicture}`} width="80px" />
        ) : (
          ""
        ),
      },
      {
        Component: Input,
        label: "First Name",
        type: "text",
        name: "first_name",
        registerFields: {
          required: true,
          pattern: /^[A-Za-z ]+$/,
        },
        registerFieldsFeedback: {
          pattern: "Name can only contain letters.",
        },
      },
      {
        Component: Input,
        label: "Last Name",
        type: "text",
        name: "last_name",
        registerFields: {
          required: true,
          pattern: /^[A-Za-z ]+$/,
        },
        registerFieldsFeedback: {
          pattern: "Name can only contain letters.",
        },
      },
      {
        Component: Input,
        label: "Email",
        type: "email",
        name: "email",
        registerFields: {
          required: true,
          pattern:
            // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/,
        },
        registerFieldsFeedback: {
          pattern: "The email field must be a valid email address.",
        },
      },
      {
        Component: SelectInput,
        label: "Gender",
        name: "gender",
        registerFields: {
          required: true,
        },
        children: (
          <>
            <option value="">Select</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </>
        ),
      },
      {
        Component: SelectInput,
        label: "Status",
        name: "status",
        registerFields: {
          required: false,
        },
        children: (
          <>
            <option value="">Select</option>
            {status?.map((val) => {
              return (
                <option key={val.id} value={val.id}>
                  {val.name}
                </option>
              );
            })}
          </>
        ),
      },
      {
        Component: SelectInput,
        label: "Lives In",
        name: "lives_in",
        registerFields: {
          required: false,
        },
        children: (
          <>
            <option value="">Select</option>
            {livesIn?.map((val) => {
              return (
                <option key={val.id} value={val.id}>
                  {val.name}
                </option>
              );
            })}
          </>
        ),
      },
      {
        Component: Input,
        label: "Birth Year",
        type: "Number",
        name: "yob",
        registerFields: {
          required: true,
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Candidate"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/candidate" /*backPageNum: page */ },
            name: "Back To Candidates",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Edit Candidate
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
