import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import {
  RenderInputFields,
  SubmitButton,
  Input,
  CKEditorInput,
} from "../../components/Form/Form";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    clearErrors,
  } = useForm();

  const ckEditorRef = useRef();

  const { response, request } = useRequest();

  const history = useHistory();

  useEffect(() => {
    document.title = "Add Status - Native";
  }, []);

  useEffect(() => {
    if (response) {
      toast.success("Status has been added successfully.");
      history.push("/status");
    }
  }, [response]);

  const onSubmit = (data) => {
    let { name } = data;
    request("POST", "master/user-status", { name });
  };

  const InputFields = [
    [
      {
        Component: Input,
        type: "text",
        label: "Name",
        name: "name",
        colClass: "col-lg-6",
        registerFields: {
          required: true,
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add Status"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/status", name: "Back To Status" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-12">
                  {/* <h3 className="mb-10 font-weight-bold text-dark">
                    Add New Block
                  </h3> */}

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
