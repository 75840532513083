import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import {
  Input,
  RenderInputFields,
  SelectInput,
  SubmitButton,
  Textarea,
} from "../../components/Form/Form";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const { response, request } = useRequest();

  const history = useHistory();

  useEffect(() => {
    document.title = "Add Testimonial - Native";
  }, []);

  useEffect(() => {
    if (response) {
      toast.success("Testimonial has been added successfully.");
      history.push("/testimonial");
    }
  }, [response]);

  const onSubmit = (data) => {
    let { image, name, designation, comment } = data;

    let formData = new FormData();

    formData.append("name", name);
    formData.append("designation", designation);
    formData.append("comment", comment);

    if (image && image[0]) {
      if (image[0].size > 3030000) {
        setError("image", {
          type: "manual",
          custom: "company logo size",
          message: "Image size cannot be more then 3 mb.",
        });
        return;
      }
      formData.append("image", image[0]);
    }

    request("POST", "testimonial", formData);
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "Image",
        type: "file",
        name: "image",
        registerFields: {
          required: true,
        },
        registerFieldsFeedback: {
          manual: "Image cannot be more then 3 mb.",
        },
      },
      {
        Component: Input,
        label: "Name",
        type: "text",
        name: "name",
        registerFields: {
          required: {
            value: true,
          },
          // pattern: {
          //   value: /^[a-zA-Z0-9 ]*$/,
          //   message: "Name must contain only alphabets and numbers",
          // },
        },
        registerFieldsFeedback: {
          pattern: "Legal name can only contain alphabets.",
        },
      },
      {
        Component: Input,
        label: "Designation",
        type: "text",
        name: "designation",
        registerFields: {
          required: true,
        },
      },

      {
        Component: Textarea,
        label: "Comment",
        type: "textarea",
        name: "comment",
        registerFields: {
          required: true,
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add Testimonial"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/testimonial", name: "Back To Testimonials" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    {/* Add Testimonial */}
                  </h3>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />
                    <div className="row"></div>
                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
