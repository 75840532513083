import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { API } from "../../constant/api";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import {
  Textarea,
  RenderInputFields,
  SubmitButton,
  Input,
  CKEditorInput,
} from "../../components/Form/Form";

const Edit = (props) => {
  const { id: blockId } = props.match.params;
  const history = useHistory();

  const ckEditorRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    reset,
    setError,
    clearErrors,
  } = useForm();

  const { response: fetchJobCategoryRes, request: fetchJobCategoryReq } =
    useRequest();

  const { response, request } = useRequest();

  useEffect(() => {
    if (blockId) {
      fetchJobCategoryReq("GET", `master/job-category/${blockId}`);
      document.title = "Edit Job Category - Native";
    }
  }, [blockId]);

  useEffect(() => {
    if (fetchJobCategoryRes) {
      const { name } = fetchJobCategoryRes.data;
      // setValue("name", name);
      // setValue("title", title);
      // setValue("description", description);
      reset({ name });
    }
  }, [fetchJobCategoryRes]);

  useEffect(() => {
    if (response) {
      toast.success("Job Categories has been updated successfully.");
      history.push("/job-category");
    }
  }, [response]);

  const onSubmit = (data) => {
    // console.log(data);
    // return
    let { name } = data;

    request("PUT", "master/job-category", { name, id: blockId });
  };

  const InputFields = [
    [
      {
        Component: Input,
        type: "text",
        label: "Name",
        name: "name",
        colClass: "col-lg-6",
        registerFields: {
          required: true,
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Job Categories"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/job-category" },
            name: "Back To Job Categories",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-12">
                  {/* <h3 className="mb-10 font-weight-bold text-dark">
                    Edit Block
                  </h3> */}

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
