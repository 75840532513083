import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { API } from "../../constant/api";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import {
  Textarea,
  RenderInputFields,
  SubmitButton,
  Input,
  CKEditorInput,
} from "../../components/Form/Form";

const Edit = (props) => {
  const { id: blockId } = props.match.params;

  const history = useHistory();

  const [oldImage, setOldImage] = useState("");
  const ckEditorRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    reset,
    setError,
    clearErrors,
  } = useForm();

  const { response: fetchBlockRes, request: fetchBlockReq } = useRequest();

  const { response, request } = useRequest();

  useEffect(() => {
    if (blockId) {
      fetchBlockReq("GET", `block/${blockId}`);
      document.title = "Edit Block - Native";
    }
  }, [blockId]);

  useEffect(() => {
    if (fetchBlockRes) {
      const { page, title, description, image } = fetchBlockRes.block;
      // setValue("page", page);
      // setValue("title", title);
      // setValue("description", description);

      reset({ page, title, description });

      if (image) {
        setOldImage(image);
      }
    }
  }, [fetchBlockRes]);

  useEffect(() => {
    if (response) {
      toast.success("Block has been updated successfully.");
      history.push("/block");
    }
  }, [response]);

  const onSubmit = (data) => {
    // console.log(data);
    // return

    let { page, title, image, description } = data;

    let formData = new FormData();

    formData.append("id", blockId);
    formData.append("page", page);
    formData.append("title", title);
    formData.append("description", description);

    if (image && image[0]) {
      formData.append("image", image[0]);
    }

    request("PUT", "block", formData);
  };

  const InputFields = [
    [
      {
        Component: Input,
        type: "text",
        label: "Page",
        name: "page",
        colClass: "col-lg-6",
        registerFields: {
          required: true,
        },
        inputData: {
          disabled: true,
        },
      },
      {
        Component: Input,
        type: "text",
        label: "Title",
        name: "title",
        colClass: "col-lg-6",
        registerFields: {
          required: true,
        },
        inputData: {
          disabled: true,
        },
      },
      {
        Component: Input,
        label: "Image",
        type: "file",
        name: "image",
        colClass: "col-lg-6",
        registerFields: {
          //   required: true,
        },
        children: oldImage ? (
          <img src={`${API.PORT}/${oldImage}`} width="100px" />
        ) : (
          ""
        ),
      },
    ],
    [
      {
        Component: CKEditorInput,
        colClass: "col-xl-12 mt-10",
        label: "Description",
        name: "description",
        registerFields: {
          required: false,
        },
        otherRegisterFields: {
          manual: false,
        },

        inputData: {
          onInstanceReady: (editor) => {
            ckEditorRef.current = editor;
          },
        },
        getValues,
        setValue,
        trigger,
        clearErrors,
        isEdit: true,
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Block"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/block" },
            name: "Back To Blocks",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-12">
                  {/* <h3 className="mb-10 font-weight-bold text-dark">
                    Edit Block
                  </h3> */}

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
