import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import {
  Input,
  RenderInputFields,
  SubmitButton,
  SelectInput,
} from "../../components/Form/Form";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
    setValue,
    control,
  } = useForm();

  const history = useHistory();

  const [status, setStatus] = useState();
  const [livesIn, setLivesIn] = useState();

  const { response, request } = useRequest();
  const { request: requestStatus, response: responseStatus } = useRequest();
  const { request: requestLivesIn, response: responseLivesIn } = useRequest();

  useEffect(() => {
    requestStatus("GET", "master/user-status");
    requestLivesIn("GET", "master/countries");
  }, []);

  useEffect(() => {
    if (responseStatus && responseStatus.status) {
      setStatus(responseStatus?.list);
    }
  }, [responseStatus]);

  useEffect(() => {
    if (responseLivesIn && responseLivesIn.status) {
      setLivesIn(responseLivesIn?.list?.record);
    }
  }, [responseLivesIn]);

  useEffect(() => {
    document.title = "Add Candidate - Native";
  }, []);
  useEffect(() => {
    if (response) {
      toast.success("Job Seeker has been added successfully.");
      history.push("/candidate");
    }
  }, [response]);

  const onSubmit = (data) => {
    let {
      profile_picture,
      first_name,
      last_name,
      email,
      password,
      confirmpassword,
      gender,
      status,
      lives_in,
      yob,
    } = data;

    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      setError("email", {
        type: "manual",
      });
      return;
    }

    if (password !== confirmpassword) {
      setError("confirmpassword", {
        type: "manual",
      });
      return;
    }
    let formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("gender", gender);
    formData.append("status", status);
    formData.append("lives_in", lives_in);
    formData.append("yob", yob);
    if (profile_picture && profile_picture[0]) {
      formData.append("profile_picture", profile_picture[0]);
    }

    request("POST", "user/create", formData);
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "Profile Picture",
        type: "file",
        name: "profile_picture",
        registerFields: {
          //   required: true,
        },
      },
      {
        Component: Input,
        label: "First Name",
        type: "text",
        name: "first_name",
        registerFields: {
          required: {
            value: true,
          },
          pattern: {
            value: /^[a-zA-Z]*$/,
            message: "First Name must contain only alphabets.",
          },
        },
        registerFieldsFeedback: {
          pattern: "First name can only contain alphabets.",
        },
      },

      {
        Component: Input,
        label: "Last Name",
        type: "text",
        name: "last_name",
        registerFields: {
          required: {
            value: true,
          },
          pattern: {
            value: /^[a-zA-Z]*$/,
            message: "Last name must contain only alphabets.",
          },
        },
        registerFieldsFeedback: {
          pattern: "Last name can only contain alphabets.",
        },
      },
      {
        Component: Input,
        label: "Email",
        type: "email",
        name: "email",
        registerFields: {
          required: true,
          pattern:
            // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/,
        },
        registerFieldsFeedback: {
          pattern: "The email field must be a valid email address.",
        },
      },
      {
        Component: Input,
        label: "Password",
        type: "password",
        name: "password",
        registerFields: {
          required: true,
          pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
        },
        registerFieldsFeedback: {
          pattern:
            "Password must be of 8 characters long with atleast one Uppercase, one Lowercase and one Number",
        },
      },

      {
        Component: Input,
        label: "Confirm Password",
        type: "password",
        name: "confirmpassword",
        registerFields: {
          required: true,
          pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
        },
        registerFieldsFeedback: {
          pattern:
            "Confirm password must be of 8 characters long with atleast one Uppercase, one Lowercase and one Number",
        },
        otherRegisterFields: {
          manual: true,
          feedback: "Both Passwords do not match.",
        },
      },
      {
        Component: SelectInput,
        label: "Gender",
        name: "gender",
        registerFields: {
          required: true,
        },
        children: (
          <>
            <option value="">Select</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </>
        ),
      },
      {
        Component: SelectInput,
        label: "Status",
        name: "status",
        registerFields: {
          required: false,
        },
        children: (
          <>
            <option value="">Select</option>
            {status?.map((val) => {
              return (
                <option key={val.id} value={val.id}>
                  {val.name}
                </option>
              );
            })}
          </>
        ),
      },
      {
        Component: SelectInput,
        label: "Lives In",
        name: "lives_in",
        registerFields: {
          required: false,
        },
        children: (
          <>
            <option value="">Select</option>
            {livesIn?.map((val) => {
              return (
                <option key={val.id} value={val.id}>
                  {val.name}
                </option>
              );
            })}
          </>
        ),
      },
      {
        Component: Input,
        label: "Birth Year",
        type: "Number",
        name: "yob",
        registerFields: {
          required: true,
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add Candidate"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/candidate", name: "Back To Candidates" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                {/* <div className="col-xl-1"></div> */}
                <div className="col-xl-12">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Add Candidate
                  </h3>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />
                    <div className="row"></div>
                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                {/* <div className="col-xl-1"></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
